import React from "react"

export default function ImpactBox(props){
    const {name,onClick,topLeftCorner=false,topRightCorner=false,bottomLeftCorner=false,bottomRightCorner=false,selected=false}=props

    function onBoxClick(){
        onClick();
    }

    return <div onClick={()=>{onBoxClick()}} className={`impact-box ${selected?"active":""} ${topLeftCorner?"top-left-corner":""} ${bottomLeftCorner?"bottom-left-corner":""} ${topRightCorner?"top-right-corner":""} ${bottomRightCorner?"bottom-right-corner":""}`}>
        <span>{name}</span>
    </div>
}