import React,{useEffect} from "react";
import {floorplan,hotspot} from "../../static/images"



import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {isMobile} from "react-device-detect"

import SocialMedia from "../overlays/components/social_media";

const researchSids=["6KoOF8MEoYZ","6KoOF8MEoYZ","ofFaL2b1kvi"]

export default function Map(props){
    const {
        navToHotspot,
        show,
        setShow,
        persona
        
    }=props

    const researchSid=researchSids[persona]

    

    const sids=["CAjjldUUQgt","x7Z1TsxpK3N","dThyn6XqF3d",researchSid,"syhZxqIP6Tj"]

    function onClose(){
        setShow(false);
    }

    function onClickHotspot(sid,open){
        onClose();
        navToHotspot(sid,open);
    }

  

    if(!show){
        return (<></>)
    }

    return(<div className="map-container animate__animated animate__fadeIn animate__faster overflow-auto">
        <Container className="h-100 d-flex flex-column justify-content-center">
            <Row className="text-center">
                <Col>
                    <h5 className="map-header">Select any of the zones to begin your tour!</h5>
                </Col>
            </Row>
            <Row className="mt-2 flex-nowrap">
                <Col xs={isMobile?{span:8,offset:2}:{span:10,offset:1}} md={{span:8,offset:2}}>
                    <div className="map-wrapper">
                        <img src={floorplan} alt="map"></img>

                        <div className="entry-point-wrapper intro">
                            <img 
                            onClick={()=>{onClickHotspot(sids[0],false)}}
                            className={`hotspot ${persona===0?"glow":""}`}
                            alt="hostpot"
                            src={hotspot}>   
                            </img>
                            <span className="index">1</span>
                            

                            <div className="entry-name-wrapper">
                                <span className="entry-name">Introduction</span>
                            </div>
                            
                        </div>

                        <div className="entry-point-wrapper about">
                            <img 
                            onClick={()=>{onClickHotspot(sids[1],true)}}
                            className={`hotspot `}
                            alt="hostpot"
                            src={hotspot}>   
                            </img>
                            <span className="index">2</span>
                            <div className="entry-name-wrapper">
                                <span className="entry-name">About<br/>Us</span>
                            </div>         
                        </div>

                        <div className="entry-point-wrapper program">
                            <img 
                            onClick={()=>{onClickHotspot(sids[2],true)}}
                            className={`hotspot ${persona===1?"glow":""}`} alt="hostpot"
                            src={hotspot}>   
                            </img>
                            <span className="index">3</span>
                            

                            <div className="entry-name-wrapper">
                                <span className="entry-name">Our Programmes</span>
                            </div>
                            
                        </div>

                        <div className="entry-point-wrapper research">
                            <img 
                            onClick={()=>{onClickHotspot(sids[3],true)}}
                            className={`hotspot ${persona===2?"glow":""}`} alt="hostpot"
                            src={hotspot}>   
                            </img>
                            <span className="index">4</span>
                            

                            <div className="entry-name-wrapper">
                                <span className="entry-name">Our Research</span>
                            </div>
                            
                        </div>

                        <div className="entry-point-wrapper professor">
                            <img 
                            onClick={()=>{onClickHotspot(sids[4],true)}}
                            className={`hotspot`} alt="hostpot"
                            src={hotspot}>   
                            </img>
                            <span className="index">5</span>
                            

                            <div className="entry-name-wrapper">
                                <span className="entry-name">Our Professorships</span>
                            </div>
                            
                        </div>

                        
                    </div>
                </Col>
                
                <Col style={{justifyContent:"flex-end"}} className="d-flex p-0  align-items-center">
                    <SocialMedia style={{marginRight:"10px"}}></SocialMedia>
                </Col>
               

                
            </Row>

            <Row className="text-center mt-5">
                <Col>
                    <span className="map-footer">WELCOME FOYER</span>
                </Col>
            </Row>

            
        </Container>
    </div>);
};
