import React,{useState,useEffect} from "react";


import {loading_gif} from "../../static/images"



import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'

import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import Overlay from "./components/overlay"

import SubmissionResults from "./components/submission_result"

import {isMobile} from "react-device-detect";

import {API_URL_EMAIL_INFO} from "../../config"

const header=(<>Thank You For Visiting!</>)
const subheader="Hope we have inspired you!";

const content={
    bodyPara_0:[
        <>
        Follow our developments and learn about <a className="theme-link" href="https://www.nie.edu.sg" target="_blank" rel="noopener noreferrer">our impact</a> as Singapore’s national teacher education institute.
        </>,
        "Join us and learn from the best in teacher education. The NIE is ranked as one of the world’s best educational institutions. We are part of the Nanyang Technological University, one of Asia’s top universities. Find out more.",
        <>
        Join us as a researcher or collaborate with us. At the NIE, we believe that education research improves the quality of learning and teaching. We strive to engage in rigorous and relevant education research that advances practice and policy in Singapore and internationally.
        <br/><br/>
        Find out more about our <a className="theme-link" href="https://www.nie.edu.sg/research/projects" target="_blank" rel="noopener noreferrer">research</a>.
        <br/><br/>
        <a className="theme-link" href="https://www.nie.edu.sg/research/research-offices/" target="_blank" rel="noopener noreferrer">Collaborate</a> with us on a research project.
        </>

    ]
}


export default function Ending(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;

    const [selectedBox, setSelectedBox] = useState(undefined);
    const [email, setEmail] = useState("");
    const [title,setTitle]=useState("Please Select");
    const [name, setName] = useState("");
    const [org,setOrg]=useState("");
    const [nameEmpty, setNameEmpty] = useState(false);
    const [emailEmpty, setEmailEmpty] = useState(false);
    const [titleEmpty, setTitleEmpty] = useState(false);

    const [emailInvalid, setEmailInvalid] = useState(false);

    const [status,setStatus]=useState(0);  //0:form 1:submitting 2:successful
    const [success,setSuccess]=useState(true)
    
    

    const options=[
        {value:"Mr",label:"Mr"},
        {value:"Ms",label:"Ms"},
        {value:"Mrs",label:"Mrs"},
        {value:"Dr",label:"Dr"},
        {value:"Prof",label:"Prof"},
    ]

    const dropItems=options.map((item,index)=>{
        return <Dropdown.Item key={`drop-item-${index}`} className="theme" onSelect={(key)=>setTitle(key)} eventKey={item.value}>{item.label}</Dropdown.Item>
    })


    function onSubmit(){
        // console.log("Submit to server placeholder")
        

        name===""?setNameEmpty(true):setNameEmpty(false);

        email===""?setEmailEmpty(true):setEmailEmpty(false);

        title==="Please Select"?setTitleEmpty(true):setTitleEmpty(false);

        var emailValid=ValidateEmail();
        
        if(name&&email&&title!=="Please Select"&&emailValid){
            setStatus(1);

            const data={
                name:name,
                email:email,
                title:title,
                organization:org
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };

            fetch(API_URL_EMAIL_INFO,requestOptions)
            .then(res=>{setStatus(2);console.log(res);setSuccess(res.ok)})
            .catch(e=>{setSuccess(false)})
        }
    }

    function ValidateEmail()
    {
        if(email!==""){
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if(email.match(mailformat))
            {
                setEmailInvalid(false);
                return true;
            }
            else
            {
                setEmailInvalid(true);
                return false;
            }
        }
        setEmailInvalid(false);
        return false;
        
    }

    var onClose=()=>{
        setShow(false);
        reset();
        setStatus(0);
    }

    function reset(){
        setEmail("");
        setTitle("Please Select");
        setName("");
        setOrg("");
        setNameEmpty(false)
        setEmailEmpty(false);
        setTitleEmpty(false);
        setEmailInvalid(false);
        setSelectedBox(undefined);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();     
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }


        return(<div className={`${isMobile?"ending-container-mobile":"ending-container"} animate__animated animate__fadeIn animate__faster`}>
        

            <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100">
                        
                        <BodyHeader header={<>Ready to take the next step?</>}></BodyHeader>
                        
                    </Row>

                    <Row className="w-100">
                        
                        <BodyParagraph paragraph={content.bodyPara_0[persona]}>
                        </BodyParagraph>
                        
                    </Row>

                    {persona===1?
                    <>
                        <Row>
                            <Col xs={12} className="d-flex justify-content-center mt-4">
                                <a href="https://www.nie.edu.sg/teacher-education/" target="_blank" rel="noreferrer noopener">
                                <div className="box-left">
                                    <span>Teacher Education</span>
                                </div>
                                </a>
                                <a href="https://www.nie.edu.sg/higher-degrees/" target="_blank" rel="noreferrer noopener">
                                <div className="box-center ml-4">
                                    <span>Higher Degrees</span>
                                </div>
                                </a>
                                <a href="https://www.nie.edu.sg/professional-and-leadership-development/" target="_blank" rel="noreferrer noopener">
                                <div className="box-right ml-4">
                                    <span>Professional Learning and Leadership Development</span>
                                </div>
                                </a>
                            </Col>
                        </Row>
                    </>:<></>}
                    
                    <Row className="w-100 mt-4">

                        <Col>
                            <section className="mailing-list-section">
                                <Row>
                                    <BodyHeader underlineStyle={{backgroundColor:"#165F8E"}}  header={<>Join our mailing list</>}></BodyHeader>
                                </Row>

                                <Row >
                        
                                    <BodyParagraph paragraph={"Subscribe to receive news and updates from the NIE."}></BodyParagraph>
                                    
                                </Row>

                                <Row className={`${status===0?"d-flex":"d-none"} `}>
                                    <Col xs={{span:10,offset:1}} className="mt-4">
                                        <Row className="">

                                            <Col xs="auto" className="d-flex flex-column">           
                                                <label className={`${selectedBox===0?"primary-label":"grey-label"} flex-grow-0`} htmlFor="title">Title</label>
                                                <Dropdown onFocus={()=>{setSelectedBox(0)}} onBlur={()=>{setSelectedBox(undefined)}} name="title" className="theme">
                                                        <Dropdown.Toggle  variant={""} className="dropdown-toggle theme">
                                                            <span style={{color:title==="Please Select"?"":"#015783"}}>{title}</span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="theme">
                                                            {dropItems}
                                                        </Dropdown.Menu>
                                                </Dropdown>
                                                    <span style={{display:titleEmpty?"":"none"}} className="empty-message">Must select a title</span>
                                                
                                            </Col>

                                            <Col className="d-flex flex-column">
                                                <label className={`${selectedBox===1?"primary-label":"grey-label"}`} htmlFor="name">Full Name</label>
                                                <input className="secondary-input" onFocus={()=>{setSelectedBox(1)}} onBlur={()=>{setSelectedBox(undefined)}} onChange={(event)=>{setName(event.target.value)}} type="text" name="name" placeholder=""></input>
                                                <span style={{display:nameEmpty?"":"none"}} className="empty-message">Field must not be empty</span>
                                            </Col>


                                        </Row>

                                        <Row className=" mt-4">
                                        <Col >
                                            <label className={`${selectedBox===2?"primary-label":"grey-label"}`} htmlFor="email">Email</label>
                                            <input className="secondary-input" onFocus={()=>{setSelectedBox(2)}} onBlur={()=>{setSelectedBox(undefined)}} onChange={(event)=>{setEmail(event.target.value)}} type="email" name="email" placeholder=""></input>
                                            <span style={{display:emailEmpty?"":"none"}} className="empty-message">Field must not be empty</span>
                                            <span style={{display:emailInvalid?"":"none"}} className="empty-message">Invalid email address</span>
                                        </Col>
                                        <Col >
                                            <label className={`${selectedBox===3?"primary-label":"grey-label"}`} htmlFor="org">Organisation</label>
                                            <input className="secondary-input" onFocus={()=>{setSelectedBox(3)}} onBlur={()=>{setSelectedBox(undefined)}} onChange={(event)=>{setOrg(event.target.value)}} type="text" name="org" placeholder=""></input>
                                        </Col>
                                        </Row>

                                        <Row className="w-100 d-flex justify-content-center mt-5">
                                            <Col xs="auto">
                                                <button onClick={()=>{onSubmit()}} className="theme-btn">SUBMIT</button>
                                            </Col>
                                        </Row>
                                    
                                    </Col>
                                </Row>

                                <Row  className={` justify-content-center w-100 mt-5 ${status===1?"d-flex":"d-none"}`}>
                                    <Col xs={2}>
                                        <img className="loading w-100" alt="loading" src={loading_gif}></img>
                                    </Col>
                                </Row>

                                <SubmissionResults submit={onSubmit} show={status===2} success={success}></SubmissionResults>
                            </section>
                        </Col>
                    
                        
                    
                    </Row>

                                
                   
                
                
        </Overlay>
        
    </div>);
 
    };
