import React,{useEffect} from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"

import {isMobile} from "react-device-detect";

import Overlay from "./components/overlay"

const header=<>OUR PROGRAMMES</>;
const subheader=<>Discover the NIE’s programmes that are guided by the 4-Life framework</>

const bodyHeader_0="Introduction";

const content={
    bodyPara_0:[
        <>
        The NIE’s programmes combine a legacy of educational excellence with innovation and creativity to enable our students to be future-ready. Guided by the 4-Life framework – Life-long, Life-deep, Life-wide and Life-wise – our programmes prepare them for life through purposeful learning (and not just for performance).
        <br/><br/>
        Whether they are aspiring or in-service teachers, or working professionals, we have the right programme for them in Initial Teacher Preparation (ITP) or Graduate Studies and Professional Learning (GPL).
        </>,
        <>
        The NIE’s programmes combine a legacy of educational excellence with innovation and creativity to enable our students to be future-ready. Guided by the 4-Life framework – Life-long, Life-deep, Life-wide and Life-wise – our programmes prepare them for life through purposeful learning 
        (and not just for performance).
        <br/><br/>
        Whether you are an aspiring or in-service teacher, or a professional, we have the right programme in Initial Teacher Preparation (ITP) or Graduate Studies and Professional Learning (GPL) for you.
        </>,
        <>
        The NIE’s programmes combine a legacy of educational excellence with innovation and creativity to enable our students to be future-ready. Guided by the 4-Life framework – Life-long, Life-deep, Life-wide and Life-wise – our programmes prepare them for life through purposeful learning (and not just for performance).
        <br/><br/>
        Whether they are aspiring or in-service teachers, or working professionals, we have the right programme for them in Initial Teacher Preparation (ITP) or Graduate Studies and Professional Learning (GPL).
        </>
    ]
}

export default function Programmes(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow,navigate,sdk,showButton}=props;


    var onClose=()=>{
        setShow(false);
        
    }

  

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    function onBtnClick(){
        onClose();
        navigate("wzF03Onag7S",true,sdk.Mattertag.Transition.FLY);
    }

    if(!show){
        return (<></>)
    }

        
        return(<div className={`${isMobile?"programmes-container-mobile":"programmes-container"} animate__animated animate__fadeIn animate__faster"`}>
        
            <Overlay  header={header} subheader={subheader} onClose={onClose}>
                <Row className="w-100">   
                    <BodyHeader header={bodyHeader_0}></BodyHeader>    
                </Row>
                        
                <Row className="w-100">  
                    <BodyParagraph paragraph={content.bodyPara_0[persona]}></BodyParagraph>
                </Row>

                {showButton?
                    <Row className="mt-4">
                        <Col xs="auto">
                            <button style={{textTransform:"none"}} className="theme-btn-auto" onClick={()=>{onBtnClick()}}>Explore the NIE Experience</button>
                        </Col>
                    </Row>
                    :
                    <></>
                }

                
            </Overlay>
 
        </div>);
        
    


    
};
