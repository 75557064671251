import React,{useEffect} from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Overlay from "./components/overlay"

import {isMobile} from "react-device-detect";

const header=<>Our legacy</>;
const subheader=<>We could not have come this far without our pioneers, whose dedication to 
teacher education led us to ever greater heights</>;

const vidHeader_0="Hear their stories in Walking Down Memory Lane: The NIE Story";
const vidTitle_0="Walking Down Memory Lane: The NIE Story";
const vidUrl_0="https://www.youtube.com/embed/9Dijz8NHlyQ"



export default function Legacy(props){
    const {onCloseCallback,onOpenCallback,show,setShow}=props;


    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }

        
    return(<div className={`${isMobile?"legacy-container-mobile":"legacy-container"} animate__animated animate__fadeIn animate__faster`}>

        <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100 d-flex justify-content-center">
                <Col xs="auto">
                    <h3 className="video-header mb-4">
                    {vidHeader_0}
                    </h3>
                </Col>
            </Row>
                    
            <Row className="w-100">
                <Col xs={{span:10,offset:1}}>
                    <div className="aspect-ratio-16-9">
                        <iframe title={vidTitle_0} className="YTB-video" src={vidUrl_0} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>  
                </Col>
            </Row>
        </Overlay>
        
    </div>)
    };
