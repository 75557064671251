import React,{useEffect,useState} from "react";

import {left_arrow,right_arrow} from "../../../static/images"

export default function FixedScrollMenu(props){
    const {data,onSelect,rowSize=7}=props;

    const [colArr,setColArr]=useState([])

    const [colNumber, setColNumber] = useState(0);

    const [currentCol, setCurrentCol] = useState(0);

    function loadCol(){
        var cols=[]
        while(data.length>0){
            cols.push(data.splice(0,7));
        }
        var proccessedCols= cols.map((col,index)=>{
            setColNumber(index);
            return (<div key={`col-${index}`} className="item-col">
                {col}
            </div>)
        })

        setColArr(proccessedCols)
    }

    function onClickArrow(dir){
        if(dir===0){
            //left
            if(currentCol>0){
                setCurrentCol(currentCol-1);
            }
        }
        else{
            //right
            if(currentCol<colNumber){
                setCurrentCol(currentCol+1)
            }
        }
    }
    

    useEffect(() => {
        loadCol();
    }, [data]);

    return (
        <>
        <div className="fixed-scroll-menu-wrapper">

           
            
            <div style={{transform:`translateX(-${currentCol*100}%)`}} className="cols-wrapper">
                {colArr}
            </div>
           

        </div>
         
        <img onClick={()=>{onClickArrow(0)}} alt="arrow" src={left_arrow} className="arrow arrow__left"></img>
        <img onClick={()=>{onClickArrow(1)}} alt="arrow" src={right_arrow} className="arrow arrow__right"></img>
        </>
    )
}