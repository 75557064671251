import React,{useEffect,useState} from 'react';

import './App.scss';
import Tour from "./app/tour"

import {API_URL_SESSION_INFO} from "./config"

function checkOrientation(){
  switch(window.orientation) {
    case 0:
      return "portrait";
      

    case -90:
      return "landscape";   
      

    case 90:
      return "landscape";     
      

    case 180:
      return "portrait";
      
    default:
      return "portrait";

}
}


function App() {
  const [persona, setPersona] = useState(0);
  const [screenOrien, setScreenOrien] = useState(checkOrientation());
  const [counters, setCounters] = useState({
    session:0,
    entrance:0,
    intro:0,
    legacy:0,
    papi:0,
    NIEExp:0,
    programmes:0,
    zoneDesign:0,
    ITP:0,
    GPL:0,
    research:0,
    touchpad:0,
    professorship:0,
    connection:0,
    ending:0
  });

  function sendSessionData(url){
    const currentPersona=persona
    var data = { 
      persona:currentPersona,
      counters:counters
    };
    // console.log(url);
    data=JSON.stringify(data)
    navigator.sendBeacon(url, data);
}
  function onOrienChangeCallback(){
    setScreenOrien(checkOrientation());
  }

  function orientationListener(){
    onOrienChangeCallback();
  }

  function beforeUnloadListener(){
    sendSessionData(API_URL_SESSION_INFO);
  }

  useEffect(() => {
    window.addEventListener("orientationchange",orientationListener);

    
    return () => {
      window.removeEventListener("orientationchange",orientationListener)

      
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload",beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload",beforeUnloadListener);
    };
  }, [persona]);

  return (
    <div className="App">
     
      <Tour persona={persona} setPersona={setPersona} counters={counters} screenOrien={screenOrien}></Tour>
      {/* <button onClick={()=>{sendSessionData(API_URL_SESSION_INFO,persona)}} className="test-btn btn-primary" style={{position:"fixed",top:"0",right:"0"}}>Test</button> */}
    </div>
  );
}

export default App;
