import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {orientation_sign} from "../../static/images"


export default function OrientationBlock(props){
    const {orientation="portrait",showWelcome}=props;

    if(showWelcome){
        return (<></>);
    }

    if(!orientation.startsWith("portrait")){
        return (<></>);
    }

    return (
        <Container fluid className="orientation-block-container animate__animated animate__fadeIn">
            <Row>
                <Col xs="auto">
                    <img alt="sign" style={{width:"200px"}} src={orientation_sign}></img>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={{span:10,offset:1}}>
                    <p>For the best experience, <br/>Please rotate your phone horizontally.</p>
                </Col>
            </Row>
        </Container>
        )
}