import React,{useEffect} from "react";



import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import BodyHeader from "../overlays/components/body_header"
import BodyParagraph from "../overlays/components/body_paragraph"


import Overlay from "../overlays/components/overlay"

import {isMobile,BrowserView, MobileView} from "react-device-detect"

import SocialMedia from "../overlays/components/social_media"

//const email="niepr@nie.edu.sg"

const header="CONTACT US";
const subheader="We would love to hear from you. Please drop us a line if you have any queries";

const bodyHeader_0="Email Us";
const bodyPara_0=<>For further assistance, please contact us at <a className="theme-link" href="mailto:niepr@nie.edu.sg">niepr@nie.edu.sg.</a></>

export default function Contact(props){
    const {onCloseCallback,onOpenCallback,show,setShow}=props;

    var onClose=()=>{
        setShow(false);
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
  
        
        return(<><div className={`${isMobile?"contact-container-mobile":"contact-container"} animate__animated animate__fadeIn animate__faster`}>
        
        <Overlay  header={header} subheader={subheader} closeButton={!isMobile} onClose={onClose}>

            {isMobile?
                        <SocialMedia style={{position:"absolute",right:"25px",top:"30px"}}></SocialMedia>
                    :<></>}
            <Row className="w-100 ">
                <BodyHeader header={bodyHeader_0}></BodyHeader>
                   
            </Row>

            <Row className="w-100 ">
                <BodyParagraph paragraph={bodyPara_0}></BodyParagraph>     
            </Row>
        </Overlay>
        
        
    </div>

        {!isMobile?<BrowserView>
            <SocialMedia style={{position:"absolute",right:"7%",top:"50%",transform:"translateY(-50%)",pointerEvents:"initial"}}></SocialMedia>
        </BrowserView>:<></>}
        
    </>);
        
};
