import React,{useEffect,useState} from "react"


export default function CollapseButton(props){
    const {setShowObject,showObject,showMap,showNIEExp,showingOverlay}=props;

    const [colorClass,setColorClass]=useState("white")
    // var colorClass;

    
    useEffect(() => {

        var color =()=>{
            if(showObject){
                return "blue"
            }
            else{
                if(!showMap&&!showNIEExp&&!showingOverlay){
                    return "white"
                }
                else{
                    return "blue";
                }
            }
        }
        
        setColorClass(color());
    }, [showingOverlay,showObject,showMap,showNIEExp]);

    return (
        <div onClick={()=>{setShowObject(!showObject)}} className={`collapse-btn-wrapper hamburger hamburger--vortex ${showObject?"is-active":""}`}>
            <span className="hamburger-box">
                <span className={`hamburger-inner ${colorClass}`}></span>
            </span>
        </div>
    )
}