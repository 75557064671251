import React,{useState,useEffect,useRef} from "react";

import {MATTERPORT_SDK_KEY,MATTERPORT_URL} from "../config"

import Sidebar from "./sidebar/sidebar"
import Map from "./sidebar/map"
import Contact from "./sidebar/contact"

import Welcome from "./overlays/welcome"

import Entrance from "./overlays/entrance"
import Intro from "./overlays/intro"
import Legacy from "./overlays/legacy"
import Papi from "./overlays/papi"
import NIEExp from "./overlays/NIEExp"
import Progremmes from "./overlays/programmes"
import Backbone from "./overlays/backbone"
import ITP from "./overlays/ITP"
import Research from "./overlays/research"
import GPL from "./overlays/GPL"
import Professorship from "./overlays/professorship"
import Connection from "./overlays/connection"
import Ending from "./overlays/ending"

import OrientationBlock from "./overlays/orientation_block";

import CollapseButton from "./overlays/components/collapse_button"
import {isMobile, MobileView,isIOS} from "react-device-detect"

import {mute_icon,unmute_icon} from "../static/images";

import {bgm} from "../static/sounds"

import {sleep} from "./utils/utils"





var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}


var muteLerp;
var playLerp;

export default function Tour(props){

    const {screenOrien,counters,persona,setPersona} =props;

    const [showcaseIframe,setShowcaseIframe]=useState(undefined)
    const [sdk,setSdk]=useState(undefined)
    const [sdkReady,setSdkReady]=useState(false);
  
    const [counterStarted,setCounterStarted]=useState(false);
    
    
    var mattertags;

    const testing=false;


    // const [persona,setPersona]=useState(0);   //0:general  1:programmes 2:research

    const [showWelcome,setShowWelcome]=useState(!testing);
    const showWelcomeRef=useRef({});
    showWelcomeRef.current=showWelcome;

    const [showMap, setShowMap] = useState(!testing);
    
    const [showSidebar, setShowSidebar] = useState(false);
    
    const [showContactUs,setShowContactUs]=useState(false)

    const [showBackground,setShowBackground]=useState(false);



    const [showEntrance, setShowEntrance] = useState(false);
    const [showIntro,setShowIntro]=useState(false);
    const [showLegacy, setShowLegacy] = useState(false);
    const [showPapi, setShowPapi] = useState(false);
    const [showNIEExp, setShowNIEExp] = useState(false);
    const [showProgrammes, setShowProgrammes] = useState(false);
    const [showZoneDesign, setShowZoneDesign] = useState(false);
    const [showITP, setShowITP] = useState(false);
    const [showGPL, setShowGPL] = useState(false);
    const [showResearch, setShowResearch] = useState(false);
    const [showTouchpad,setShowTouchpad]=useState(false);
    const [showProfessorship, setShowProfessorship] = useState(false);
    const [showConnection, setShowConnection] = useState(false);
    const [showEnding, setShowEnding] = useState(false);

    const entranceRef=useRef({})
    entranceRef.current=showEntrance;
    const introRef=useRef({})
    introRef.current=showIntro;
    const legacyRef=useRef({})
    legacyRef.current=showLegacy;
    const papiRef=useRef({})
    papiRef.current=showPapi;
    const NIEExpRef=useRef({})
    NIEExpRef.current=showNIEExp;
    const programmesRef=useRef({})
    programmesRef.current=showProgrammes;
    const zoneDesignRef=useRef({})
    zoneDesignRef.current=showZoneDesign;
    const ITPRef=useRef({})
    ITPRef.current=showITP;
    const GPLRef=useRef({})
    GPLRef.current=showGPL;
    const researchRef=useRef({})
    researchRef.current=showResearch;
    const touchpadRef=useRef({})
    touchpadRef.current=showTouchpad;
    const professorshipRef=useRef({})
    professorshipRef.current=showProfessorship;
    const connectionRef=useRef({})
    connectionRef.current=showConnection;
    const endingRef=useRef({})
    endingRef.current=showEnding;

    const [muted,setMuted]=useState(testing);
    const mutedRef=useRef({})
    mutedRef.current=muted;

    const [bgmIsPlaying,setBgmIsPlaying]=useState(false);
    const bgmIsPlayingRef=useRef({})
    bgmIsPlayingRef.current=bgmIsPlaying;

    const [showingOverlay,setShowingOverlay]=useState(false);


    // useEffect(() => {
      
      
      
    // }, [persona]);


    function closeAllOverlays(){
      setShowEntrance(false);
      setShowIntro(false);
      setShowLegacy(false);
      setShowPapi(false);
      setShowNIEExp(false);
      setShowProgrammes(false);
      setShowZoneDesign(false);
      setShowITP(false);
      setShowGPL(false);
      setShowResearch(false);
      setShowTouchpad(false);
      setShowProfessorship(false);
      setShowConnection(false);
      setShowEnding(false);
      setShowingOverlay(false);
    }

    const mute_music_overlay_table={
      "CAjjldUUQgt":false,//setShowEntrance,//"Entrance"
      "x7Z1TsxpK3N":false,//setShowIntro,  //intro
      "vBPYXXJvbqE":true,  //our legacy
      "UXgfdghAQx8":true,  //"Papilionanda"
      "wzF03Onag7S":true,//"The NIE exp"
      "dThyn6XqF3d":false,//Ourprogrammes
      "rMc2hkLTNA4":false,//ZoneDesign
      "oigCOhNWe0m":persona!==2,//"Vision"/ITP,
      "ABTg5qLQlz4":persona===0, //top of the game
      "6KoOF8MEoYZ":persona===1, //Education research
      "ofFaL2b1kvi":false,//"touch pad "
      "syhZxqIP6Tj":false,//"professorship"
      "aNdwSdfI8Pe":false,//Globally Connected
      "GRYTHnAVb7i":false, //"Selfie Screen"
    }
    
    

    const hotspot_overlay_table={
      "CAjjldUUQgt":setShowEntrance,//setShowEntrance,//"Entrance"
      "x7Z1TsxpK3N":setShowIntro,//setShowIntro,  //intro
      "vBPYXXJvbqE":setShowLegacy,  //our legacy
      "UXgfdghAQx8":setShowPapi,  //"Papilionanda"
      "wzF03Onag7S":setShowNIEExp,//"The NIE exp"
      "dThyn6XqF3d":setShowProgrammes,//Ourprogrammes
      "rMc2hkLTNA4":setShowZoneDesign,//ZoneDesign
      "oigCOhNWe0m":setShowITP,//"Vision"/itp,
      "ABTg5qLQlz4":setShowGPL, //top of the game
      "6KoOF8MEoYZ":setShowResearch, //Education research
      "ofFaL2b1kvi":setShowResearch,//"touch pad //professorship"
      "syhZxqIP6Tj":setShowProfessorship,//"professorship"
      "aNdwSdfI8Pe":setShowConnection,//Globally Connected
      "GRYTHnAVb7i":setShowEnding, //"Selfie Screen"
    }

    const hotspot_sweep_table={
      "CAjjldUUQgt":{
        sweep:"c74534a6a96347afae86f4f8b3183d77",
        rotation:{x:-8,y:179}
      },//"Entrance"
      "x7Z1TsxpK3N":{
        sweep:"fc08599d28784c1784be0b8a35c2a66a",
        rotation:{x:-1,y:-7}
      },  //intro
      "vBPYXXJvbqE":{
        sweep:"414ebcbc798941d29d660fa3f1cbe176",
        rotation:{x:2,y:3.5}
      },  //our legacy
      "UXgfdghAQx8":{
        sweep:"1ca8e7e70a1c45f4ac1dafd69db4b492",
        rotation:{x:-21.5,y:-92.7}
      },  //"Papilionanda"
      "wzF03Onag7S":{
        sweep:"e8d0f0c2faf043c387766f716b3d08e4",
        rotation:{x:-7.5442165425385195,y:-87.99039554545323}
      },//"Animation Board"
      "dThyn6XqF3d":{
        sweep:"005cb22795be4fbab3b1133d937dca9a",
        rotation:{x:-6.714632906855703,y:-178.5832843592911}
      },//Teachers
      "rMc2hkLTNA4":{
        sweep:"1a9cb4670a70461c815d50e163f2f031",
        rotation:{x:-7.110350536272332,y:99.36029017061671}
      },//"Program space?"
      "oigCOhNWe0m":{
        sweep:"92820bea38aa46cebe187ea4a01f7d3f",
        rotation:{x:-2.6287030013069153,y:159.2536624070649}
      },//"Vision",
      "ABTg5qLQlz4":{
        sweep:"f55ff14059394c7ab8516b0e7f1d4b5e",
        rotation:{x:8.520016872297292,y:12.687714909475961}
      }, //top of the game
      "6KoOF8MEoYZ":{
        sweep:"f595571e932e40adb4359e69f37b0ac4",
        rotation:{x:-3.3574684706484232,y:-178.4181113016611}
      }, //Education research
      "ofFaL2b1kvi":{
        sweep:"77342d02598d4e8ca1822b787c2c6334",
        rotation:{x:-47.52086888656043,y:89.53897476780215}
      },//"touch pad"
      "syhZxqIP6Tj":{
        sweep:"1406029709ae418880b620eda395946a",
        rotation:{x:2.8627379358552227,y:-93.11633225450551}
      },//"professorship"
      "aNdwSdfI8Pe":{
        sweep:"6265a9b73e424f229303b782d2bf054a",
        rotation:{x:-1.9783392948535077,y:89.3498689603594}
      },//Globally Connected
      "GRYTHnAVb7i":{
        sweep:"3b82df62c1254812bafa87edadcc1038",
        rotation:{x:-7.164219597442778,y:2.5686683136918305}
      }, //"Selfie Screen"
    }


    useEffect(() => {
      if(sdk){
        sdk.App.state.subscribe(async function (appState) {
               
  
          if(appState.phase === sdk.App.Phase.LOADING) {
            
            //console.log('The app has started loading!')
          }
          if(appState.phase === sdk.App.Phase.STARTING) {
           // console.log('The transition into the start location begins!')   
          }
          if(appState.phase === sdk.App.Phase.PLAYING) {

            // registerIcons();
            // replaceIcons();
            //console.log('The app is ready to take user input now!')
       
            mattertags=await sdk.Mattertag.getData()
            .catch((e)=>{console.error(e)});
            // console.log(mattertags)
            initListener();
            setSdkReady(true);
         };

    })
      }
    }, [sdk]);

    function showcaseLoader(iframe) {

      try {
        window.MP_SDK.connect(
          iframe, // Obtained earlier
          MATTERPORT_SDK_KEY, // Your API key//
          '3.6' // SDK version you are using
          // Use the latest version you can for your app
          )
          .then(async (mpSDK)=>{
              console.log("Setting SDK State");
              setSdk(mpSDK);
  
        })
          .catch((e)=>{
            console.error(e);
            console.error("Connection failed, try again")
            console.error("Reconnecting...");
            showcaseLoader(iframe);
          });
      }
      catch (e) {
        console.error(e);
        console.error("Reconnecting...");
        showcaseLoader(iframe);
      }
    
        
    };



    async function loadShowcase(){
      
      if(document.getElementById('showcase-iframe')){
        setShowcaseIframe(document.getElementById('showcase-iframe'))
      }
      else{
        console.log("iframe not found, retrying...");
          setTimeout(()=>{
            loadShowcase();
        },200)
      }
  }


    useEffect(() => {
      if(showcaseIframe){
        console.log("Loading showcase");
        showcaseLoader(showcaseIframe);
      }
      else{
        console.log("Component mounted, setting state...");
        loadShowcase();
      }
      
      
    }, [showcaseIframe]);

    useEffect(() => {

      

      if(persona!==2 &&sdkReady){
        sdk.Mattertag.editOpacity("6KoOF8MEoYZ",1);
        sdk.Mattertag.editOpacity("ofFaL2b1kvi",0);
      }

      if(persona===2&&sdkReady){
        sdk.Mattertag.editOpacity("6KoOF8MEoYZ",0);
        sdk.Mattertag.editOpacity("ofFaL2b1kvi",1);
      }

     
      if(sdkReady){

        sdk.on(sdk.Mattertag.Event.CLICK,matterTagClickCallback)
        sdk.on(sdk.Sweep.Event.ENTER,sweepEnterCallback)
      }
      
      return ()=>{
        if(sdkReady){
          sdk.off(sdk.Mattertag.Event.CLICK,matterTagClickCallback)
          sdk.off(sdk.Sweep.Event.ENTER,sweepEnterCallback)
        }
      }
     
      
    }, [sdkReady,persona]);


    function matterTagClickCallback(sid){
      // console.log("Mattertag clicked: "+sid);
      navToHotspotSweep(sid,true,sdk.Mattertag.Transition.FLY)
    }

    function sweepEnterCallback(oldSweep,newSweep){
      // console.log("Left Sweep: "+oldSweep);
      // console.log("Entered Sweep: "+newSweep);
    }
    

    function initListener(){
     
      // sdk.on(sdk.Mattertag.Event.CLICK,matterTagClickCallback)
      // sdk.on(sdk.Sweep.Event.ENTER,sweepEnterCallback)
      // sdk.on(sdk.Sweep.Event.EXIT,sweepExitCallback)
      // sdk.on(sdk.Mode.Event.CHANGE_START,modeChangeStartCallback)
  
      if(mattertags.length>0){
        // console.log(matterTags)
        for (var i = 0;i<mattertags.length;i++){
          
          sdk.Mattertag.preventAction(mattertags[i].sid,{
            navigating:true
          })
        }
      }
  
               
    
}


    // function navToHotspot(sid,open,transition=sdk.Mattertag.Transition.FADEOUT){
      
    //   if(sdk){
    //     sdk.Mattertag.navigateToTag(sid,transition)
    //     .then(()=>{
    //       if(open){
    //         var setState=hotspot_overlay_table[`${sid}`];   
    //         if(setState){
    //           setState(true);
    //           console.log("opening overlay")
    //         }
    //       }
    //     })
    //   }
    //   else{
    //     console.error("SDK not loaded, please retry...");
    //   }
    // }

    function navToHotspotSweep(sid,open,transition=sdk.Mattertag.Transition.FADEOUT){
      sdk.Sweep.moveTo(hotspot_sweep_table[`${sid}`].sweep, {
        rotation: hotspot_sweep_table[`${sid}`].rotation,//{x:30,y:45},
        transition: transition,
       // transitionTime: transitionTime,
      })
      .then(()=>{
        if(open){

          var setState=hotspot_overlay_table[`${sid}`];   
            if(setState){
              setState(true);

              if(mute_music_overlay_table[sid]===true){
                setBgmIsPlaying(!mutedRef.current);
                

                if(isIOS&&isMobile){
                  
                  stopMusic();
                }
                else{
                  clearInterval(muteLerp);
                  clearInterval(playLerp);
                  muteLerp=setInterval(()=>{lerpMusic(0)},100)
                }
                
                // lerpMusic(0);
              }
              
            }

          
        }
      })
    }


    async function logCamPose(){
      console.log(await sdk.Camera.getPose())
    }

    function test(){
      // counters.session+=1;
      // console.log(counters)
      logCamPose();
    }

    //---------------------------------------------------------------------------------
    //general functions

    function overlayCloseCallback(){
      // return <Redirect  to={TOUR_PATH} />
      // history.push(TOUR_PATH);
      setShowBackground(false);
      setShowingOverlay(false);

      if(bgmIsPlayingRef.current){
        
        
        if(isIOS&&isMobile){
          
          playMusic();
        }
        else{
          clearInterval(muteLerp);
          clearInterval(playLerp);
          playLerp=setInterval(()=>{lerpMusic(1)},150)
        }
        
      }
    }
    function overlayOpenCallback(){
      setShowBackground(true);
      setShowingOverlay(true);

      
    }

    function toggleMusic(){
      if(muted){
        document.querySelector('.bgm').play();
      }
      else{
        document.querySelector('.bgm').pause()
      }

      setMuted(!muted);
      
    }

    function playMusic(){
      document.querySelector('.bgm').play();
      setMuted(false);
    }

    function stopMusic(){
      document.querySelector('.bgm').pause();
      setMuted(true);

    }

    function musicOnVisibilityChange(){
      if ('hidden' in document) {
        if(document[hidden]){
          document.querySelector('.bgm').pause();
        }
        else{
          if(!mutedRef.current&&!showWelcomeRef.current){
            document.querySelector('.bgm').play();
          }
        }
      }
      
    }


    useEffect(() => {
      document.addEventListener(visibilityChange,musicOnVisibilityChange)
      return () => {
        document.removeEventListener(visibilityChange,musicOnVisibilityChange)
      };
    }, []);

    

    // async function lerpMusic(targetVolume){
    //   var bgm=document.querySelector('.bgm');
      
    //   var counter=30;
    //   if (bgm.volume-targetVolume>0){
        
    //     while(bgm.volume>targetVolume&&counter>=0){
    //       bgm.volume=bgm.volume-0.05>=targetVolume?bgm.volume-0.05:targetVolume;
    //       counter-=1;
    //       await sleep(100);
    //     }
    //   }
    //   else if(bgm.volume-targetVolume<0){
        
    //     while(bgm.volume<targetVolume&&counter>=0){
    //       bgm.volume=bgm.volume+0.05<=targetVolume?bgm.volume+0.05:targetVolume;
    //       counter-=1;
    //       await sleep(200);
    //     }
    //   }
      
    // }

    function lerpMusic(targetVolume){
      var bgm=document.querySelector('.bgm');
      
      if (bgm.volume-targetVolume>0){
        bgm.volume=bgm.volume-0.05>=targetVolume?bgm.volume-0.05:targetVolume;
        // bgm.volume=bgm.volume-0.05>=targetVolume?bgm.volume-0.05:targetVolume;

        if(bgm.volume<=targetVolume){
          clearInterval(muteLerp);
        }
      }
      else if(bgm.volume-targetVolume<0){
        bgm.volume=bgm.volume+0.05<=targetVolume?bgm.volume+0.05:targetVolume;
        if(bgm.volume>=targetVolume){
          clearInterval(playLerp);
        }
      }
    }

    async function updateCounters(interval){

      while(true){

      if(!document[hidden]){
        
      counters.session+=interval;
      

      if(entranceRef.current){
        counters.entrance+=interval;
      }

      if(introRef.current){
        counters.intro+=interval;
      }
      if(legacyRef.current){
        counters.legacy+=interval;
      }
      if(papiRef.current){
        counters.papi+=interval;
      }
      if(NIEExpRef.current){
        counters.NIEExp+=interval;
      }
      if(programmesRef.current){
        counters.programmes+=interval;
      }
      if(zoneDesignRef.current){
        counters.zoneDesign+=interval;
      }
      if(ITPRef.current){
        counters.ITP+=interval;
      }
      if(GPLRef.current){
        counters.GPL+=interval;
      }
      if(researchRef.current){
        counters.research+=interval;
      }
      if(touchpadRef.current){
        counters.touchpad+=interval;
      }
      if(professorshipRef.current){
        counters.professorship+=interval;
      }
      if(connectionRef.current){
        counters.connection+=interval;
      }
      if(endingRef.current){
        counters.ending+=interval;
      }
    }

      // console.log(counters)
      await sleep(interval*1000)
    }
    }





    useEffect(() => {
      // const intervalTime=1000
      // var sessionInterval=setInterval(()=>{updateCounters(intervalTime)},intervalTime)
      // return () => {
      //   clearInterval(sessionInterval)
      // };
      if(!counterStarted){
        updateCounters(1);
        setCounterStarted(true)
      }
    }, [counterStarted]);


    const overlays=<div className={`center-wrapper`}> 
    <div style={{pointerEvents:"initial"}} className={`background ${showBackground&&!isMobile?"animate__animated animate_fadeIn":"invisible"}`}></div>
    <Entrance persona={persona} show={showEntrance} setShow={setShowEntrance} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Entrance>
    <Intro persona={persona} show={showIntro} setShow={setShowIntro} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Intro>
    <Legacy show={showLegacy} setShow={setShowLegacy} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Legacy>
    <Papi show={showPapi} setShow={setShowPapi} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Papi>
    <NIEExp show={showNIEExp} setShow={setShowNIEExp} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></NIEExp>
    <Progremmes showButton={counters.NIEExp===0} sdk={sdk} navigate={navToHotspotSweep} persona={persona} show={showProgrammes} setShow={setShowProgrammes} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Progremmes>
    <Backbone  show={showZoneDesign} setShow={setShowZoneDesign} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Backbone>
    <ITP persona={persona} show={showITP} setShow={setShowITP} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></ITP>
    <GPL persona={persona}  show={showGPL} setShow={setShowGPL} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></GPL>
    <Research persona={persona} show={showResearch} setShow={setShowResearch} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Research>
    <Professorship persona={persona}  show={showProfessorship} setShow={setShowProfessorship} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Professorship>
    <Connection persona={persona} show={showConnection} setShow={setShowConnection} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Connection>
    <Ending persona={persona} show={showEnding} setShow={setShowEnding} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}></Ending>

    <Contact show={showContactUs} setShow={setShowContactUs} onCloseCallback={overlayCloseCallback} onOpenCallback={overlayOpenCallback}/>

  </div>


    return (

            <div  className="main-layout-wrapper">

              

                <Sidebar
                  show={showSidebar}
                  showMap={showMap}
                  setShowMap={setShowMap}
                  showContactUs={showContactUs}
                  setShowContactUs={setShowContactUs}
                  closeAllOverlays={closeAllOverlays}
                  
                  />


                <div className="iframe-wrapper">
                  <iframe 
                    title="MPShowcase"
                    src={MATTERPORT_URL}
                    frameBorder="0" allowFullScreen={false}
                    id="showcase-iframe">
                  </iframe>

                  {/* <div className="iframe-filter"></div> */}

                  {/* space for right screen overlays */}
                  
                  
                  <Map persona={persona} show={showMap} setShow={setShowMap} navToHotspot={navToHotspotSweep}></Map>
                  
                  {isMobile?(
                  overlays
                ):(<></>)}


                <span onClick={()=>{toggleMusic()}} className="music-wrapper">
                <img alt="music" className="w-100" src={muted?mute_icon:unmute_icon}></img>
                <audio loop={true} className="bgm" controls={false} src={bgm}></audio>
                </span>

                    
                </div>


                {/* space for full/any screen overlays */}
                <Welcome playMusic={playMusic} setPersona={setPersona} show={showWelcome} setShow={setShowWelcome} ></Welcome>

                {!isMobile?(
                  overlays
                ):(<></>)}
                

                <MobileView>
                  <div style={{position:"fixed",top:"0px",left:"0px",zIndex:"19"}}>
                    <CollapseButton showingOverlay={showingOverlay} setShowObject={setShowSidebar} showObject={showSidebar}></CollapseButton>
                  </div>
                </MobileView>
                
                  
              <MobileView>
                <OrientationBlock orientation={screenOrien} showWelcome={showWelcome}></OrientationBlock>
              </MobileView>
                  
                

              {/* <button onClick={()=>{test()}} className="test-btn btn-primary" style={{position:"fixed",top:"0",right:"0"}}>Test</button> */}
            
              
            </div>

    )
}