import React,{useEffect} from "react";


import {} from "../../static/images"


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import HeaderSection from "./components/header_section"
import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import CloseButton from "./components/close_button"
import {isMobile} from "react-device-detect";

import Overlay from "./components/overlay"

const header=<>INITIAL TEACHER PREPARATION (ITP)</>
const subheader=<>Discover our programme philosophy and signature ITP programme features</>

const bodyHeader_0="Introduction";


// const bodyHeader_1="What is the NIE undergraduate experience like?";
// const bodyPara_1="Hear about it from Pearlwe, our Teaching Scholars Programme scholar."

// const vidTitle_0="NTU-NIE Teaching Scholars Programme";
// const vidUrl_0="https://www.youtube.com/embed/9wBgSfzd3yY"

const content={
    bodyPara_0:[
        "The NIE is Singapore’s national institute for teacher education. Our ITP programmes prepare student teachers with the subject knowledge, pedagogical knowledge and values they need to thrive in a dynamic teaching career.",
        "The NIE is Singapore’s national institute for teacher education. Our ITP programmes will prepare you with the subject knowledge, pedagogical skills and values you need to thrive in a dynamic teaching career.",
        "The NIE is Singapore’s national institute for teacher education. Our ITP programmes prepare student teachers with the subject knowledge, pedagogical knowledge and values they need to thrive in a dynamic teaching career. Through an introductory course to educational research, undergraduate student teachers are guided by faculty mentors as they explore a topic of mutual interest and experience the education research process."],
    bodyHeader_1:[
        "What makes the NIE one of the top teacher education institutions in the world?",
        "What is the NIE undergraduate experience like?",
        "",
    ],
    bodyPara_1:[
        "Find out in From Strength to Strength.",
        "Hear about it from Pearlwe, our Teaching Scholars Programme scholar. ",
        ""
    ],
    vidUrl_0:[
        "https://www.youtube.com/embed/8dMMlz_afhA",
        "https://www.youtube.com/embed/9wBgSfzd3yY",
        ""
    ],
    vidTitle_0:[
        "From Strength to Strength",
        "NTU-NIE Teaching Scholars Programme",
        ""
    ]
}


export default function ITP(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;

    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
   
        
    return(<div className={`${isMobile?"ITP-container-mobile":"ITP-container"} animate__animated animate__fadeIn animate__faster`}>

    <Overlay  header={header} subheader={subheader} onClose={onClose}>
        <Row className="w-100">   
            <BodyHeader header={bodyHeader_0}></BodyHeader>    
        </Row>
                
        <Row className="w-100">  
            <BodyParagraph paragraph={content.bodyPara_0[persona]}></BodyParagraph>
        </Row>

        {persona!==2?
        <>
            <Row className="w-100 mt-4">   
                <BodyHeader header={content.bodyHeader_1[persona]}></BodyHeader>    
            </Row>
                
            <Row className="w-100">  
                <BodyParagraph paragraph={content.bodyPara_1[persona]}></BodyParagraph>
            </Row>

            <Row className="w-100">
                <Col xs={{span:10,offset:1}}>
                    <div className="aspect-ratio-16-9">
                        <iframe title={content.vidTitle_0[persona]} className="YTB-video" src={content.vidUrl_0[persona]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Col>
            </Row>
        </>:<></>}

        
    </Overlay>

    </div>);
   
   
};
