export function detectSwipe(startX,endX){
    if (startX-endX>=30){
        return 0;  //swipe from right to left
    }
    else if(startX-endX<=-30){
        return 1; //swipe from left to right
    }
    else {
        return false;
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  