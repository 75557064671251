//general
export const white_cross=require('./images/general/white_cross.png')
export const black_cross=require('./images/general/black_cross.png')
export const orientation_sign=require('./images/general/orientation.png')
export const loading_gif=require("./images/general/loading.gif")

export const mute_icon=require("./images/general/muteIcon.png");
export const unmute_icon=require("./images/general/unmuteIcon.png");

export const left_arrow=require("./images/general/left_arrow.png");
export const right_arrow=require("./images/general/right_arrow.png");
//map

export const floorplan=require('./images/map/floorplan.png')
export const logo=require('./images/general/logo.png')
export const nie_logo=require('./images/general/nie_logo.png')
export const ntu_logo=require('./images/general/ntu_logo.png')
export const hotspot=require('./images/map/hotspot.png')


//NIEExp
export const touchpoint_wall=require("./images/touchpoints/touchpoint_wall.jpg")
    
export const thumbnail_01_unselected=require('./images/touchpoints/unselected/unselected1.png')
export const thumbnail_02_unselected=require('./images/touchpoints/unselected/unselected2.png')
export const thumbnail_03_unselected=require('./images/touchpoints/unselected/unselected3.png')
export const thumbnail_04_unselected=require('./images/touchpoints/unselected/unselected4.png')
export const thumbnail_05_unselected=require('./images/touchpoints/unselected/unselected5.png')
export const thumbnail_06_unselected=require('./images/touchpoints/unselected/unselected6.png')
export const thumbnail_07_unselected=require('./images/touchpoints/unselected/unselected7.png')
export const thumbnail_08_unselected=require('./images/touchpoints/unselected/unselected8.png')
export const thumbnail_09_unselected=require('./images/touchpoints/unselected/unselected9.png')
export const thumbnail_10_unselected=require('./images/touchpoints/unselected/unselected10.png')
export const thumbnail_11_unselected=require('./images/touchpoints/unselected/unselected11.png')
export const thumbnail_12_unselected=require('./images/touchpoints/unselected/unselected12.png')
export const thumbnail_13_unselected=require('./images/touchpoints/unselected/unselected13.png')

export const thumbnail_01_selected=require('./images/touchpoints/selected/selected1.png')
export const thumbnail_02_selected=require('./images/touchpoints/selected/selected2.png')
export const thumbnail_03_selected=require('./images/touchpoints/selected/selected3.png')
export const thumbnail_04_selected=require('./images/touchpoints/selected/selected4.png')
export const thumbnail_05_selected=require('./images/touchpoints/selected/selected5.png')
export const thumbnail_06_selected=require('./images/touchpoints/selected/selected6.png')
export const thumbnail_07_selected=require('./images/touchpoints/selected/selected7.png')
export const thumbnail_08_selected=require('./images/touchpoints/selected/selected8.png')
export const thumbnail_09_selected=require('./images/touchpoints/selected/selected9.png')
export const thumbnail_10_selected=require('./images/touchpoints/selected/selected10.png')
export const thumbnail_11_selected=require('./images/touchpoints/selected/selected11.png')
export const thumbnail_12_selected=require('./images/touchpoints/selected/selected12.png')
export const thumbnail_13_selected=require('./images/touchpoints/selected/selected13.png')


//GPL
export const triangle=require("./images/GPL/triangle.png")
export const GPL_card_img_01=require("./images/GPL/bernard_tung.png")
export const GPL_card_img_02=require("./images/GPL/janice_kee.png")
export const GPL_card_img_03=require("./images/GPL/malik.png")
export const GPL_card_img_04=require("./images/GPL/aliamat.png")
// export const GPL_card_img_05=require("./images/GPL/gog.png")

export const GPL_body_img_01=require("./images/GPL/bernard_tung_body.png")
export const GPL_body_img_02=require("./images/GPL/janice_kee_body.png")
export const GPL_body_img_03=require("./images/GPL/malik_body.png")
export const GPL_body_img_04=require("./images/GPL/aliamat_body.png")

export const GPL_body_text_bubble_01=require("./images/GPL/text_bubble_01.png")
export const GPL_body_text_bubble_02=require("./images/GPL/text_bubble_02.png")
export const GPL_body_text_bubble_03=require("./images/GPL/text_bubble_03.png")
export const GPL_body_text_bubble_04=require("./images/GPL/text_bubble_04.png")
// export const GPL_body_img_05=require("./images/GPL/gog.png")



//professorship
export const prof_card_img_01=require("./images/professorship/cj_koh.png")
export const prof_card_img_02=require("./images/professorship/barker.png")
export const prof_card_img_03=require("./images/professorship/lee.png")
export const prof_card_img_04=require("./images/professorship/ruth.png")


//social media
export const facebook_icon=require("./images/social_media/facebook_icon.png");
export const youtube_icon=require("./images/social_media/youtube_icon.png");
export const linkedIn_icon=require("./images/social_media/linkedIn_icon.png");
export const twitter_icon=require("./images/social_media/twitter_icon.png");
export const instagram_icon=require("./images/social_media/instagram_icon.png");


