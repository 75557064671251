import React from "react";
import {logo,nie_logo,ntu_logo} from "../../static/images"


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import {isMobile} from "react-device-detect";


export default function SideBar(props){
    const {
        show,
        showMap,
        setShowMap,
        showContactUs,
        setShowContactUs,
        closeAllOverlays
    }=props

    
    

    function hideAll(){
        setShowMap(false)
        setShowContactUs(false);
    }


    function onItemClick(state,setState){
        closeAllOverlays();
        hideAll();
        setState(!state);
    }



    if(!isMobile){
        return(<Container className={`sidebar-container`}>
       <Row>
           <Col>
                <a href="https://www.nie.edu.sg/about-us/campus-facilities/NIEVLC" target="_blank" rel="noopener noreferrer"><img className="w-100" alt="logo" src={logo}></img></a>
           </Col>
       </Row>
       <Row >
            <Col xs={{span:10,offset:1}} className="d-flex flex-column justify-content-center">
                <p className="sidebar-text">Welcome to the NIE Visitors Learning Centre. <br/><br/>The centre was officially launched on 5 April 2018 by 
Mr Ng Chee Meng, Minister for Education (Schools)
(2016–2018).</p>
           </Col>
       </Row>

       <Row className="mt-5">
            <Col xs={12} className="d-flex flex-column justify-content-center">
                
            <Row>
            <Col className="d-flex justify-content-center">
            <div onClick={()=>{onItemClick(showContactUs,setShowContactUs)}} className={`${showContactUs?"active":""} mb-5 icon-col clickable text-center d-flex flex-column justify-content-center`}>
            <i style={{fontSize:"1.8rem"}} className="icon far fa-smile  mb-3"></i>
            <span className="icon-text">Contact Us</span>
            </div>
                    
                    
                    
            <div onClick={()=>{onItemClick(showMap,setShowMap)}} className={`${showMap?"active":""}  mb-5 icon-col clickable text-center d-flex flex-column justify-content-center ml-5 mr-4`}>
                <i style={{fontSize:"1.8rem"}} className="icon far fa-map  mb-3"></i>
                <span className="icon-text">Map</span>
            </div>

            </Col>
            </Row>         
                    
                

            <Row className="mt-4 mb-3">
                <Col xs={6} className="d-flex justify-content-center right-border">
                    <a href="https://www.nie.edu.sg/" target="_blank" rel="noopener noreferrer"><img className="bottom-logo" alt="logo" src={nie_logo}></img></a>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                <a href="https://www.ntu.edu.sg/Pages/home.aspx" target="_blank" rel="noopener noreferrer"><img className="bottom-logo" alt="logo" src={ntu_logo}></img></a>
                </Col>
            </Row>
                
           </Col>
       </Row>
       
    </Container>);
    }

    
    return(<Container className={`sidebar-container-mobile ${show? "active" :"inactive"}`}>
        <Row className="mt-2">
            <Col xs={{span:8,offset:4}}>
                <a href="https://www.nie.edu.sg/about-us/campus-facilities/NIEVLC" target="_blank" rel="noopener noreferrer"><img className="w-100" alt="logo" src={logo}></img></a>
            </Col>
        </Row>
        <Row >
            <Col xs={{span:10,offset:1}} className="d-flex flex-column justify-content-center">
                <p className="sidebar-text">Welcome to the NIE Visitors Learning Centre. <br/><br/>The centre was officially launched on 5 April 2018 by 
Mr Ng Chee Meng, Minister for Education (Schools)
(2016–2018).</p>
            </Col>
        </Row>

        <Row className="">
            <Col xs={12} className="d-flex flex-column justify-content-center">
                <Row className="mt-5 mb-4">
                    <Col xs={{span:5,offset:1}} onClick={()=>{onItemClick(showContactUs,setShowContactUs)}} className={`${showContactUs?"active":""}  icon-col clickable w-100  text-center flex-column d-flex justify-content-start align-items-center`} >
                    
                        <i style={{fontSize:"1.5rem"}} className="icon far fa-smile "></i>
                        <span className="icon-text mt-2">Contact Us</span>
                    
                    </Col>
                    
                    <Col xs={5} onClick={()=>{onItemClick(showMap,setShowMap)}} className={`${showMap?"active":""}  clickable icon-col w-100  text-center d-flex flex-column justify-content-start align-items-center`}>
                        
                        <i style={{fontSize:"1.5rem"}} className="icon far fa-map "></i>
                        <span className="icon-text mt-2">Map</span>
                        
                    </Col>
                    
                </Row>

                <Row className="mt-4 mb-3">
                    <Col xs={{span:5,offset:1}} className="d-flex justify-content-center right-border">
                        <a href="https://www.nie.edu.sg/" target="_blank" rel="noopener noreferrer"><img className="bottom-logo" alt="logo" src={nie_logo}></img></a>
                    </Col>
                    <Col xs={{span:5}} className="d-flex justify-content-center">
                    <a href="https://www.ntu.edu.sg/Pages/home.aspx" target="_blank" rel="noopener noreferrer"><img className="bottom-logo" alt="logo" src={ntu_logo}></img></a>
                    </Col>
                </Row>
                
            </Col>
        </Row>
        
    </Container>)
    
    
};
