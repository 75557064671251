import React,{useEffect} from "react";


import {} from "../../static/images"

import Overlay from "./components/overlay"

import Row from 'react-bootstrap/Row'



import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import {isMobile} from "react-device-detect";

const header=<>ABOUT US</>;
const subheader=<>Discover our legacy, people and future orientation</>;

const bodyHeader_0="Introduction to the NIE";
//const bodyPara_0="Teacher education in Singapore began in 1950 with the Teachers’ Training College. It became the Institute of Education in 1973 and then the National Institute of Education (NIE) in 1991 when the NIE wasestablished as an institute of the Nanyang Technological University. Today, the NIE is the national teacher education institution inspiring learning, transforming teaching and advancing research."

const content={
    bodyPara_0:[
    "Teacher education in Singapore began in 1950 with the Teachers’ Training College. It became the Institute of Education in 1973 and then the National Institute of Education (NIE) in 1991 when the NIE was established as an institute of the Nanyang Technological University. Today, the NIE is the national teacher education institution inspiring learning, transforming teaching and advancing research.",
    "Teacher education in Singapore began in 1950 with the Teachers’ Training College. It became the Institute of Education in 1973 and then the National Institute of Education (NIE) in 1991 when the NIE was established as an institute of the Nanyang Technological University. Today, the NIE is the national teacher education institution inspiring learning, transforming teaching and advancing research.",
    "Teacher education in Singapore began in 1950 with the Teachers’ Training College. It became the Institute of Education in 1973 and then the National Institute of Education (NIE) in 1991 when the NIE was established as an institute of the Nanyang Technological University. Today, the NIE is the national teacher education institution inspiring learning, transforming teaching and advancing research. Our education research strengthens the research-practice nexus continually and advances theory and policy thinking."
    ]
}


export default function Intro(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;


    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
    

        
        return (<div className={`${isMobile?"intro-container-mobile":"intro-container"} animate__animated animate__fadeIn animate__faster`}>
    
            <Overlay  header={header} subheader={subheader} onClose={onClose}>
                <Row className="w-100">
                
                    <BodyHeader header={bodyHeader_0}></BodyHeader>
            
                </Row>
                        
                <Row className="w-100">
                    
                    <BodyParagraph paragraph={content.bodyPara_0[persona]}></BodyParagraph>
                    
                </Row>
            </Overlay>
    </div>)
   
    
};
