import React from "react"
import Col from 'react-bootstrap/Col'
import {isMobile} from "react-device-detect";

export default function BodyHeader(props){
    const {xs={span:10,offset:1},md=undefined,lg=undefined,colClass="",hClass="",header="",underlineStyle={}}=props;
    
    return (
        <Col xs={xs} md={md} lg={lg} className={`${colClass}`}>
            <h3 className={`${hClass} body-header`}>
                <span>
                {header}
                </span>
                <div style={underlineStyle} className="header-underline"></div>
            </h3>
        </Col>
    )
    
    
}