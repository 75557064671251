import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {isMobile} from "react-device-detect";

export default function HeaderSection(props){
    const {xs=10,md=10,lg=10,sectionClass="",headerClass="",paragraphClass="",header="",paragraph=""}=props;


    if(!isMobile){
        return (<Row className="header-row">
            <Col xs={xs} md={md} lg={lg} className={`header-section ${sectionClass}`}>
                <Row>
                    <Col>
                <h1 className={`header ${headerClass}`}>
                    {header}
                
                </h1>
                </Col>
                </Row>
                <Row>
                <Col>
                <p className={`subheader ${paragraphClass} mt-3`}>
                    {paragraph}
                </p>
                </Col>
                </Row>
            </Col>
        </Row>)
    }
    
    return (<Row className="header-row">
        <Col xs={xs} md={md} lg={lg} className={`header-section ${sectionClass}`}>
            
            <h1 className={`header ${headerClass}`}>
                {header}
            
            </h1>
           

            
            <p className={`subheader ${paragraphClass} mt-2 mb-2`}>
                {paragraph}
            </p>
            
        </Col>
    </Row>)
    
    
}