import React,{useEffect} from "react";


import Row from 'react-bootstrap/Row'



import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"


import {isMobile} from "react-device-detect";

import Overlay from "./components/overlay"

const content={
    bodyPara_0:["Discover the latest in teacher education and education research. Learn about our impact on the education system. Explore the NIE’s legacy and future orientation, and find out why you should stay engaged with the NIE.",
    "Explore the NIE’s teacher education programmes. Learn about our impact on the education system. Discover the story of Singapore’s teacher education development, and find out why the NIE is the right choice for you.",
    "Explore the NIE’s education research focuses. Be inspired by our research projects. Learn about our impact on the education system, and find out why you should collaborate with our researchers."
    ]
}

const header=<>WELCOME TO THE NIE VISITORS <br/>LEARNING CENTRE (NIEVLC)</>;
const subheader=<>Unable to visit us in person? Take our virtual tour instead</>;

const bodyHeader_0="Introduction to the NIEVLC";
// const bodyPara_0="Explore the NIE’s teacher education programmes. Learn about our impact on the education system. Discover the story of Singapore’s teacher education development, and find out why the NIE is the right choice for you."


export default function Entrance(props){
    const {onCloseCallback,onOpenCallback,show,setShow,persona}=props;

    var onClose=()=>{
        setShow(false);
        
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }



    return(<div className={`${isMobile?"entrance-container-mobile":"entrance-container"} animate__animated animate__fadeIn animate__faster"`}>

        <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100">   
                <BodyHeader header={bodyHeader_0}></BodyHeader>    
            </Row>
                    
            <Row className="w-100">  
                <BodyParagraph paragraph={content.bodyPara_0[persona]}></BodyParagraph>
            </Row>
        </Overlay>
       
    </div>)
     



};
