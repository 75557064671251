import React,{useState} from "react";
import {logo} from "../../static/images"


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'

const options=[
    {value:0,label:"NIE"},
    {value:1,label:"NIE's Programmes"},
    {value:2,label:"NIE's Research"},  
]

const defaultDropdownText="Please pick an option..."

export default function Welcome(props){
    const {show,setShow,playMusic,setPersona}=props;

    const [dropdownText,setDropdownText]=useState(defaultDropdownText);

    const [selected,setSelected]=useState(false);
    const [currentKey, setCurrentKey] = useState(undefined);

    
    const dropItems=options.map((item,index)=>{

        return <Dropdown.Item key={item.label} className="persona-dropdown-option" onSelect={(key)=>{setCurrentKey(key);setDropdownText(item.label);setSelected(true)}} eventKey={item.value}>{item.label}</Dropdown.Item>
    })

    function reset(){
        setDropdownText(defaultDropdownText);
        setSelected(false);
        setCurrentKey(0);
    }


    function onBtnClick(){
        playMusic()
        setShow(false);
        setPersona(parseInt(currentKey));

        reset();

    }

    if (!show){
        return (<></>)
    }

    return (
        <div className="welcome-container animate__animated animate__fadeIn animate__faster">
            <Container fluid>
                <Row className="d-flex justify-content-center">
                    <Col xs="auto">
                        <img className="logo" alt="logo" src={logo}></img>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center ">
                    <Col xs="auto">
                        <p className="welcome-text">
                        Welcome to the NIE Visitors Learning Centre!
                        {/* <br/> */}
                        {/* What would you like to know more about today? */}
                        </p>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center mt-5">
                    <Col xs="auto">
                        <h4 className="dropdown-label">
                            I want to know more about...
                        </h4>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center mt-4">
                    <Col xl={6} lg={7} md={8} xs={10}>
                        <Dropdown  className="persona-dropdown">
                            <Dropdown.Toggle  variant={""} className="persona-dropdown-toggle">
                                <span style={{color:selected?"#015783":""}}>{dropdownText}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="persona-dropdown-menu">
                                {dropItems}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                
                <Row style={{opacity:selected?"1":"0",pointerEvents:selected?"initial":'none'}} className="d-flex justify-content-center mt-5">
                    <Col xs="auto">
                        <button onClick={()=>{onBtnClick()}} className="theme-btn-auto">Start Tour</button>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}