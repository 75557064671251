export const touchpoint_01=require("./videos/touchpoints/NIE_touchpoint_01.mp4");
export const touchpoint_02=require("./videos/touchpoints/NIE_touchpoint_02.mp4")
export const touchpoint_03=require("./videos/touchpoints/NIE_touchpoint_03.mp4")
export const touchpoint_04=require("./videos/touchpoints/NIE_touchpoint_04.mp4")
export const touchpoint_05=require("./videos/touchpoints/NIE_touchpoint_05.mp4")
export const touchpoint_06=require("./videos/touchpoints/NIE_touchpoint_06.mp4")
export const touchpoint_07=require("./videos/touchpoints/NIE_touchpoint_07.mp4")
export const touchpoint_08=require("./videos/touchpoints/NIE_touchpoint_08.mp4")
export const touchpoint_09=require("./videos/touchpoints/NIE_touchpoint_09.mp4")
export const touchpoint_10=require("./videos/touchpoints/NIE_touchpoint_10.mp4")
export const touchpoint_11=require("./videos/touchpoints/NIE_touchpoint_11.mp4")
export const touchpoint_12=require("./videos/touchpoints/NIE_touchpoint_12.mp4")
export const touchpoint_13=require("./videos/touchpoints/NIE_touchpoint_13.mp4")