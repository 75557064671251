import React,{useState,useEffect} from "react";

import FixedScrollMenu from "./components/fixed_scroll_menu"

import {
    thumbnail_01_unselected,
    thumbnail_02_unselected,
    thumbnail_03_unselected,
    thumbnail_04_unselected,
    thumbnail_05_unselected,
    thumbnail_06_unselected,
    thumbnail_07_unselected,
    thumbnail_08_unselected,
    thumbnail_09_unselected,
    thumbnail_10_unselected,
    thumbnail_11_unselected,
    thumbnail_12_unselected,
    thumbnail_13_unselected,

    thumbnail_01_selected,
    thumbnail_02_selected,
    thumbnail_03_selected,
    thumbnail_04_selected,
    thumbnail_05_selected,
    thumbnail_06_selected,
    thumbnail_07_selected,
    thumbnail_08_selected,
    thumbnail_09_selected,
    thumbnail_10_selected,
    thumbnail_11_selected,
    thumbnail_12_selected,
    thumbnail_13_selected,

    touchpoint_wall
} from "../../static/images"
import {
    touchpoint_01,
    touchpoint_02,
    touchpoint_03,
    touchpoint_04,
    touchpoint_05,
    touchpoint_06,
    touchpoint_07,
    touchpoint_08,
    touchpoint_09,
    touchpoint_10,
    touchpoint_11,
    touchpoint_12,
    touchpoint_13,
} from "../../static/videos"


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import HeaderSection from "./components/header_section"

import CloseButton from "./components/close_button"

import {
    isMobile,
    isTablet
  } from "react-device-detect";

import {detectSwipe} from "../utils/utils"

const TP_videos=[
    touchpoint_01,
    touchpoint_02,
    touchpoint_03,
    touchpoint_04,
    touchpoint_05,
    touchpoint_06,
    touchpoint_07,
    touchpoint_08,
    touchpoint_09,
    touchpoint_10,
    touchpoint_11,
    touchpoint_12,
    touchpoint_13]

const TP_thumbnails=[
    thumbnail_01_unselected,
    thumbnail_02_unselected,
    thumbnail_03_unselected,
    thumbnail_04_unselected,
    thumbnail_05_unselected,
    thumbnail_06_unselected,
    thumbnail_07_unselected,
    thumbnail_08_unselected,
    thumbnail_09_unselected,
    thumbnail_10_unselected,
    thumbnail_11_unselected,
    thumbnail_12_unselected,
    thumbnail_13_unselected,
]

const TP_thumbnails_selected=[
    thumbnail_01_selected,
    thumbnail_02_selected,
    thumbnail_03_selected,
    thumbnail_04_selected,
    thumbnail_05_selected,
    thumbnail_06_selected,
    thumbnail_07_selected,
    thumbnail_08_selected,
    thumbnail_09_selected,
    thumbnail_10_selected,
    thumbnail_11_selected,
    thumbnail_12_selected,
    thumbnail_13_selected,
]

export default function NIEExp(props){
    const {onCloseCallback,onOpenCallback,show,setShow}=props;

    const [selected, setSelected] = useState(0);

    const [page, setPage] = useState(0);

    const [touchStartX,setTouchStartX]=useState(0);

    
    const [playerCol,setPlayerCol]=useState(8)

    const [showWall,setShowWall]=useState(true);

    const noOfPage=4;

    var dots=[];

    var thumbnailList=[];

    var mobileThumbnailList=[];

    
    


    var onClose=()=>{
        setPage(0);
        setSelected(0);
        setShowWall(true);
        setShow(false);
        
    }

    function resetPlayer(){
        var player=document.querySelector("#player");
        player.pause();
        player.currentTime=0;
        player.play();
    }

    // function onItemSelect(key){
    //     setSelected(parseInt(key));
    //     resetPlayer();

    // }

    function onItemSelect(index){
        setSelected(index);
        resetPlayer();
        setShowWall(false);
    }

    function refreshPagination(){
        dots=[];
        for (var i=0;i<noOfPage;i++){
            const index=i;
            dots.push(<span key={`dot-${index}`} onClick={()=>{setPage(index)}} className={`dot ${page===i?"active":""}`}></span>)
        }
    }

    function refreshThumbnail(){
        thumbnailList=[];
        for(var i=0;i<13;i++){
            const index=i;
            thumbnailList.push(
                <div key={i} draggable={false} className="thumbnail-item ml-2 mr-2">
                    <img onClick={()=>{onItemSelect(index)}} draggable={false} src={index===selected?TP_thumbnails_selected[index]:TP_thumbnails[index]} alt="thumbnail"></img>
                </div>
            )
        }
    }

    function refreshMobileThumbnail(){
        
        mobileThumbnailList=[];
        for (var i=0;i<3;i++){
            var thumbnailRowComponents=[];
            const index_1=i;
            for (var j=0;j<3;j++){
                const index_2=j;
                var startingIndex=index_1*6+index_2*2;
                var thumbnailRow=TP_thumbnails.slice(startingIndex,startingIndex+2);
                
                if (thumbnailRow.length>0){
                    var cols=thumbnailRow.map((item,index)=>{
                        var currentItemIndex=startingIndex+index;
                        return (<Col key={`thumb-col-${currentItemIndex}`} className="thumbnail-wrapper d-flex justify-content-center align-items-center"  xs={{span:6}}>
                            <img style={{maxWidth:isTablet?"":"60px"}} onClick={()=>onItemSelect(currentItemIndex)} className="w-100" alt="thumbnail" src={selected===currentItemIndex&&!showWall?TP_thumbnails_selected[currentItemIndex]:TP_thumbnails[currentItemIndex]}></img>
                        </Col>)
                    })
                    thumbnailRowComponents.push(
                        <Row key={`thumb-row-${index_1*3+index_2}`} className="mt-2">
                            {cols}
                        </Row>
                    )
                }
                else{
                    break;
                }
                
            }

            mobileThumbnailList.push(

                
                <div key={`thumb-page-${i}`} className="d-flex slide col-12 flex-column justify-content-center">
                    <Row>
                        <Col style={{marginTop:"25%"}} xs={12} className="text-center hint-text">
                            Click on the buttons below to play the animations!
                        </Col>
                    </Row>

                    <Row className="mt-3 flex-grow-1">
                        <Col xs={{offset:1,span:10}}>
                            {thumbnailRowComponents}
                        </Col>
                    </Row>
                </div>

               
                
            )
        }

    }

    function detectXXL(){
        if(window.screen.width>=1400){
            setPlayerCol(6)
        }
        else{
            setPlayerCol(8)
        }
    }

    refreshThumbnail();
    if(isMobile){
        refreshPagination();
        refreshMobileThumbnail();
    }

    useEffect(() => {
        if(show){
            detectXXL();
            onOpenCallback();
            window.addEventListener('resize', detectXXL);
            
        }
        else{
            onCloseCallback();
        }
        
        return()=>{
            window.removeEventListener('resize', detectXXL);
        }
        
        
    }, [show]);


   


    useEffect(() => {
        refreshThumbnail();
        
        
        if(isMobile){
            refreshMobileThumbnail();
        }
        var player=document.querySelector("#player");
        if(player){
            player.play();
        }
    }, [selected]);

    useEffect(() => {
        
        refreshPagination()
    }, [page]);


    function onTouchStart(event){
        
        setTouchStartX(event.touches[0].clientX);
    }

    function onTouchEnd(event){

        var result=detectSwipe(touchStartX,event.changedTouches[0].clientX);

        if(result===0&&page<3){
            setPage(page+1);
        }
        else if(result===1&&page>0){
            setPage(page-1)
        }

    }


    if(!show){
        return (<></>)
    }

    
        if(!isMobile){
            return(<div className="NIEExp-container animate__animated animate__fadeIn animate__faster">
        
                <CloseButton onClick={()=>{onClose()}}></CloseButton>
                <Container>
                    

                    <HeaderSection 
                        header={<>THE NIE EXPERIENCE</>}
                        paragraph={<>What unique, enriching experiences does the NIE offer? Click on the illustrations to find out</>} 
                    >
                    </HeaderSection>

                    <Row className="body-row ">
                        <Col>
                        <Row className="d-flex justify-content-center">
                        <Col className="nowrap mt-3 p-0" xs={9} lg={8}>
                            <video className="placeholder" muted controls={false} src={TP_videos[0]}></video>
                            <div className="player-background"></div>
                            <video id="player" key={TP_videos[selected]} autoPlay  playsInline={true} controls={false}>
                                <source src={TP_videos[selected]} ></source>
                            </video>
                        </Col>
                        </Row>

                        <Col xs={12} className="text-center mt-2">
                            <p className="hint-text">Click on the buttons below to play the animations!</p>     
                        </Col>

                        <Row className="d-flex justify-content-center">
                        <Col xs={10} className="mb-4">
                            <FixedScrollMenu
                            data={thumbnailList}       
                            />
                        </Col>

                        </Row>
                        </Col>
                    
                    </Row>
                    
        </Container>
        </div>);
        }

        
            return (<div onTouchEnd={(e)=>{onTouchEnd(e)}} onTouchStart={(e)=>{onTouchStart(e)}} className="NIEExp-container-mobile animate__animated animate__fadeIn animate__faster">
        
            <CloseButton onClick={()=>{onClose()}}></CloseButton>
            <Container fluid>
                <Row className="h-100 overflow-auto">
                    <Col className="video-wrapper p-0 " xs={8}>
                        <video id="player" key={TP_videos[selected]} autoPlay={!showWall} webkit-playsinline="true" playsInline controls={false}>
                            <source src={TP_videos[selected]} ></source>
                        </video>
                        <img style={{display:showWall?"":"none",width:"100%",height:"100%",position:"absolute",top:"0",left:"0",objectFit:"cover"}} alt="wallImg" src={touchpoint_wall}></img>
                    </Col>
                    <Col style={{overflowX:"hidden"}} className="p-0 body-row">
                        <Row style={{minHeight:"85%"}}>
                            <Col >
                                
                                <div  className="slider-wrapper">
                                    <Row style={{transform:`translateX(${page*(-100)}%)`}} className="slider">
                                    <div className="h-100 w-100 d-flex flex-column justify-content-center ">
                                    <Row className="mb-4 " >
                                        <Col xs={{offset:1,span:10}}>
                                            <h1 className="header text-start overflow-hidden">THE NIE EXPERIENCE</h1>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col xs={{offset:1,span:10}}>
                                            <p className="subheader text-start">What unique, enriching experiences does the NIE offer? Click on the illustrations to find out</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{span:12}}>
                                            <p className="hint-text text-center">Swipe for the buttons!</p>
                                        </Col>
                                    </Row>
                                    </div>
                                    {mobileThumbnailList}
                                    </Row>
                                </div>
                                
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col className="pagination-wrapper">
                                {dots}
                            </Col>
                        </Row>
                    </Col>
                </Row>

                
            </Container>
    </div>);
    
};
