import React,{useState,useEffect} from "react";


import {triangle} from "../../static/images"



import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import QuoteSection from "./components/quote-section"


import {professorship_data as data} from "../store"
import {isMobile} from "react-device-detect";

import Overlay from "./components/overlay"

const header="OUR PROFESSORSHIPS";
const subheader="Find out more about our professorships and their impact on learning and teaching"

const bodyHeader_0="Introduction";
const bodyPara_0="The NIE appoints distinguished professors from around the world under our professorships. As a student, you will have the opportunity to learn from these eminent educators.";

const bodyHeader_1="Find out more about our professorships"

const content={
    bodyPara_0:[
        "The NIE appoints distinguished professors from around the world under our professorships. Whether you are our student, faculty member or alumnus, join us and learn from these eminent educators during their public lectures.",
        "The NIE appoints distinguished professors from around the world under our professorships. As a student, you will have the opportunity to learn from these eminent educators.",
        <>
        {/* “It was clear to me then, as it continues to be now, that NIE was uniquely positioned to prepare the nation's teachers, produce ground-breaking research about teaching and learning, and be a major contributor to excellence in education worldwide.”
        <br/><br/>
        ~ Marilyn Cochran-Smith, 1<sup>st</sup> CJ Koh Professor, Cawthorne Professor of Teacher Education for Urban Schools, Lynch School of Education, Boston College
        <br/><br/> */}
        The NIE appoints distinguished professors from around the world under our professorships. Students and researchers at the NIE have the opportunity to learn from these eminent educators.
        </>
    ],
    quote_0:[
        "",
        "",
        {
            quote:"“It was clear to me then, as it continues to be now, that NIE was uniquely positioned to prepare the nation's teachers, produce ground-breaking research about teaching and learning, and be a major contributor to excellence in education worldwide.”",
            author:<>~ Marilyn Cochran-Smith, 1<sup>st</sup> CJ Koh Professor, Cawthorne Professor of Teacher Education for Urban Schools, Lynch School of Education, Boston College</>
        }
    ]
}

export default function Professorship(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;

    const [selected, setSelected] = useState(0);

    const cards=data.map((item,index)=>(
    <div onClick={()=>{setSelected(index);}} key={`card-${index}`} className={`${index===selected?"active":""} ${index===0?"round-corners-left":"ml-2"} ${index===data.length-1?"round-corners-right":""} card-btn clickable`}>
        <img alt={item.name} src={item.image}></img>
        <div className="text-wrapper">
            <span className="name">{item.name}</span>
            <span className="title">{item.title}</span>
        </div>
    </div>))



    var onClose=()=>{
        setShow(false);
        setSelected(0);
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
        
        return(<div className={`${isMobile?"professorship-container-mobile":"professorship-container"} animate__animated animate__fadeIn animate__faster`}>
        
        <Overlay  header={header} subheader={subheader} onClose={onClose}>

            <Row className="w-100">   
                <BodyHeader header={bodyHeader_0}></BodyHeader>    
            </Row>

            {persona===2?
            <Row className="w-100 mb-2">
                <QuoteSection quote={content.quote_0[persona].quote} author={content.quote_0[persona].author}></QuoteSection>
            </Row>
            :<></>}
                    
            <Row className="w-100">  
                <BodyParagraph paragraph={content.bodyPara_0[persona]}></BodyParagraph>
            </Row>

            <Row className="w-100 mt-4">   
                <BodyHeader header={bodyHeader_1}></BodyHeader>    
            </Row>

            <Row >
                <Col xs={{span:10,offset:1}}  className="d-flex mt-3 justify-content-center">
                    {cards}
                </Col>
            </Row>
            
            <Row className="w-100">
                <Col xs={{span:10,offset:1}} className="mt-4 d-flex flex-column">
                    <div style={{width:`${100/data.length}%`,transform:`translate(${selected*100}%,3px)`}} className="triangle-wrapper">
                        <img alt="triangle" src={triangle}></img>
                    </div>     
                </Col>
            </Row>

                <Row className="w-100 m-0">
                    <Col xs={{span:10,offset:1}} className="body-container">
                        <Col xs={12}>
                            <h1 className="name">{data[selected].name}</h1>
                        </Col>
                        <Col xs={12}>
                            <p className="title">{data[selected].title}</p>
                            <div className="underline"></div>
                        </Col>
                        
                        <Col  xs={11}>
                            <p className="body-paragraph mt-4">{data[selected].intro}</p>
                        </Col>
                    
                </Col>
            </Row>

        </Overlay>
        
    </div>);
};
