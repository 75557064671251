import React from "react";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CloseButton from "../components/close_button"

import HeaderSection from "./header_section";
import { isMobile } from "react-device-detect";


export default function Overlay(props){

    const {className,children,header,subheader,onClose,closeButton=true}=props;

    

    return (
        <>
        {closeButton?<CloseButton onClick={()=>{onClose()}}></CloseButton>:<></>}
        
        <Container fluid={isMobile} className={`${className?className:""}`}>
            

            <HeaderSection 
                header={header}
                paragraph={subheader} 
            >
            </HeaderSection>

            <Row className="body-row flex-grow-1">
                <Col xs={12} className="body-section">
                    {children}
                </Col>
            </Row>
        </Container>
        </>
    )
    
}
