import React from "react"
import Col from 'react-bootstrap/Col'
import {isMobile} from "react-device-detect";

export default function BodyParagraph(props){
    const {xs={span:10,offset:1},md=undefined,lg=undefined,colClass="",pClass="",paragraph=""}=props;


    return (
        <Col xs={xs} md={md} lg={lg} className={`${colClass}`}>
            <p className={`${pClass} body-paragraph`}>
                {paragraph}
            </p>
        </Col>
    )
    
    
}