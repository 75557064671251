import React,{useEffect,useState} from "react";


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"


import {isMobile} from "react-device-detect";

import Overlay from "./components/overlay"
import ImpactBox from "./components/impact_box"
import ResearchImpact from "./components/research_impact"
import QuoteSection from "./components/quote-section"

import {research_data as data} from "../store"
import {triangle} from "../../static/images"

const vidUrl="https://www.youtube.com/embed/njxVtoS_xz8"


export default function Research(props){
    const {onCloseCallback,onOpenCallback,show,setShow,persona}=props;

    const [content, setContent] = useState(<></>);

    const [selected,setSelected]=useState(undefined)

    const [studyTypeBranch,setStudyBranch]=useState(0);

    const [boxWidth,setBoxWidth]=useState(150);


    useEffect(() => {
        
        const changeBoxWidth=()=>{
            
            if(window.innerWidth>=992){
                setBoxWidth(150);
            }
            else if(window.innerWidth>=768){
                setBoxWidth(125);
            }
            else{
                setBoxWidth(100);
            }
        }
        changeBoxWidth();

        window.addEventListener('resize',changeBoxWidth);
        return () => {
            window.removeEventListener('resize',changeBoxWidth);
        };
    }, []);

   

    function reset(){
        setStudyBranch(0);
        setSelected(undefined);
    }

    useEffect(() => {
        let impactBoxes=[];
        if(persona===2){
            const colNum=3;
            const rowNum=Math.ceil(data[persona].impactsShort.length/colNum) 
            // console.log("rowNum: "+rowNum);

            for (var i=0;i<rowNum;i++){
                let boxes=[];

                for(var j=0;j<colNum;j++){
                    const row=i;
                    const col=j;
                    const index=3*row+col;

                    boxes.push(
                    <Col 
                    
                    key={`box-${index}`} 
                    className="p-0 m-2">
                        <ImpactBox 
                        onClick={()=>{setSelected(index);}} 
                        name={data[persona].impactsShort[i*3+j]}
                        topLeftCorner={row===0&&col===0}
                        topRightCorner={row===0&&col===colNum-1}
                        bottomLeftCorner={row===rowNum-1&&col===0}
                        bottomRightCorner={row===rowNum-1&&col===colNum-1}
                        ></ImpactBox>
                    </Col>)
                }

                impactBoxes.push(<Row key={`boxes-row-${i}`}>
                    {boxes}
                </Row>)
            }
        }
        
        let studyTypeBoxes;
        let studyTypeCol;
        if(selected&&data[persona].studies[selected].length>1){
            studyTypeBoxes=data[persona].studies[selected].map((type,index)=>{
                return (
                
                    <Col xs="auto" key={`type-box-${index}`} className="pr-0">
                    <ImpactBox 
                    
                    onClick={()=>{setStudyBranch(index)}} 
                    topLeftCorner={index===0} 
                    bottomLeftCorner={index===0} 
                    topRightCorner={index===data[persona].studies[selected].length-1} 
                    bottomRightCorner={index===data[persona].studies[selected].length-1} 
                    name={type}
                    selected={index===studyTypeBranch}
                    />
                    </Col>
                       
                )
            })

            studyTypeCol=(<Col xs={{span:10,offset:1}}  className={`p-0 mb-1`}>
                <Row>
                    {studyTypeBoxes}
                </Row>
                
            </Col>)
        }
        

        
            switch(persona){
                case 0:
                    setContent(
                    <>
                        <Row className="w-100">
                            <BodyHeader header={data[persona].bHeaders[0]}></BodyHeader>
                        </Row>
                        <Row className="w-100">
                            <QuoteSection quote={data[persona].quotes[0].quote} author={data[persona].quotes[0].author}></QuoteSection>
                        </Row>
        
                        <Row className="w-100 mt-3">
                            <BodyParagraph paragraph={data[persona].bParas[0]}></BodyParagraph>
                        </Row>
                    </>)
                    break;
                case 1:
                
                setContent(<>
                    <Row className="w-100">
                        <BodyHeader header={data[persona].bHeaders[0]}></BodyHeader>
                    </Row>
                    <Row className="w-100">
                        <BodyParagraph paragraph={data[persona].bParas[0]}></BodyParagraph>
                    </Row>
                    <Row className="w-100">
                        <BodyHeader colClass="mt-4" header={data[persona].bHeaders[1]}></BodyHeader>
                    </Row>
                    
                    <Row className="w-100">
                    <BodyParagraph  paragraph={data[persona].bParas[1]}>
                    </BodyParagraph>
                    </Row>
                
                    <Row className="w-100">
                    <Col xs={{span:10,offset:1}}>
                        <div className="aspect-ratio-16-9">
                            <iframe title="From Strength to Strength (Dean, Education Research)" className="YTB-video" src={data[persona].vidUrls[0]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                    </Row>
                </>)
                break;
            case 2:
                setContent(
                selected===undefined?
                <>
                <Row className="w-100">        
                    <BodyHeader header={data[persona].bHeaders[0]}></BodyHeader> 
                </Row>
                <Row className="w-100">  
                    <BodyParagraph paragraph={data[persona].bParas[0]}></BodyParagraph>         
                </Row>

                <Row className="w-100 mt-3">        
                    <BodyHeader header={data[persona].bHeaders[1]}></BodyHeader> 
                </Row>

                <Row className="w-100">  
                    <BodyParagraph paragraph={data[persona].bSubheaders[0]}></BodyParagraph>         
                </Row>

                <Row className="w-100 ">
                    <Col className="d-flex flex-column align-items-center" xs={12}>
                        {impactBoxes}
                    </Col>
                </Row>
            </>
            :
            <>
                <Row className="w-100">        
                    <BodyHeader colClass="p-0" header={data[persona].impactsShort[selected]}></BodyHeader> 
                </Row>

                {studyTypeBoxes?
                <>
                    <Row className="mt-3 w-100">
                        {studyTypeCol}
                        <div className="triangle-wrapper" style={{marginLeft:"8.3%",width:`${boxWidth}px`,transform:`translate(${studyTypeBranch*100+studyTypeBranch*15/boxWidth*100}%,3px)`}}>
                            <img alt="triangle" src={triangle}></img>
                        </div>
                    </Row>
                </>
                :
                <></>}

                <ResearchImpact
                    className={studyTypeBoxes?"":"mt-4"}
                    studyName={data[persona].impactsLong[selected][studyTypeBranch]}
                    author={data[persona].authors[selected][studyTypeBranch]} 
                    studyType={data[persona].studies[selected][studyTypeBranch]} 
                    why={data[persona].why[selected][studyTypeBranch]} 
                    what={data[persona].what[selected][studyTypeBranch]}
                    back={reset}
                    >
                    

                </ResearchImpact>

                
            </>
                )
                break;
            default:
                break;
        }
       
    }, [persona,selected,studyTypeBranch,boxWidth]);


    var onClose=()=>{   
        setShow(false);  
        reset();
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
    
    return (<div className={`${isMobile?"research-container-mobile":"research-container"} animate__animated animate__fadeIn animate__faster`}>

        <Overlay  header={data[persona].header} subheader={data[persona].subheader} onClose={onClose}>
            {content}
        </Overlay>
    
        
    </div>)
}