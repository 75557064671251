
import React from "react"

import {isTablet,isMobileOnly,isMobile} from "react-device-detect"

import {
    GPL_card_img_01,GPL_card_img_02,GPL_card_img_03,GPL_card_img_04,
    GPL_body_text_bubble_01,GPL_body_text_bubble_02,GPL_body_text_bubble_03,GPL_body_text_bubble_04,
    prof_card_img_01,prof_card_img_02,prof_card_img_03,prof_card_img_04
} from "../static/images"


var GPL_body_paddings=[];

if(isTablet){
    GPL_body_paddings=["0px","15px","20px","60px"]
}
else if(isMobileOnly){
    GPL_body_paddings=["0px","0px","0px","25px"]
}
else{
    GPL_body_paddings=["0px","0px","0px","0px"]
}

var first_body_header_cols=[];

if(isMobile){
    first_body_header_cols=[7,7,7,6]
}
else{
    first_body_header_cols=[10,10,8,6];
}




export const GPL_data=[
    {
    image:GPL_card_img_01,
    name:"Bernard Tung",
    
    body:{
        title_col:7,
        first_body_header_col:first_body_header_cols[0],
        padding_top:GPL_body_paddings[0],
        title:"Master of Arts (Educational Management) and Master of Arts (Instructional Design and Technology) graduate",
        body_headers:
        ["Please share with us your professional life’s journey thus far and at which point in your career did you aspire to do a higher degree programme?",
        "What attracted you to your chosen programme at NIE?",
        "Has your experience in NIE been beneficial to you in bringing forth changes in your area of work?",
        "Being the first president for the NTU NIE Alumni Association, can you share with us your feelings about it?",
        "Pertaining to the above question, what are some of the activities/plans you have in mind to engage the alumni in NIE?"
        ],
        body_paragraphs:[
            "I am currently working at the Republic of Singapore Air Force (RSAF). Throughout my career with the RSAF, I had served various instructional and staff appointments in the area of training development for many years. When I reached the age of 40, I decided that I wanted to continue doing something related to training and education after I retire from the RSAF. Hence, I took up a Psychology degree with UNISIM (now SUSS), followed by an Master of Arts in Education Management (MAEM) with NIE and subsequently a second Master of Arts in Instructional Design and Technology (MAIDT), also with NIE.",
            "The reason for choosing MAEM was because it provided good insights into the issues in education, not just in Singapore, but also in other countries, which I find most interesting. It also gave me a deeper sense of the challenges that school leadership and teachers faced. I chose MAIDT because I was keen on solving training performance issues that were faced by governments and private institutions. In addition, this particular programme at NIE provided breadth and depth into the instructional design and technology issues. Lastly, as MAIDT is an established programme, I was confident that I would be able to learn much from the programme.",
            "Yes. It helped me tremendously in Training Needs Analysis. The experience I had gained from my studies helped me better analyse the performance problems in my workplace and derive better solutions.",
            <>I feel excited and am grateful for the opportunity to serve as the first president of the NTU NIE Alumni Association (AA). When I was going through the MAIDT programme as a student, I benefited much from my lecturer, Associate Professor Cheung Wing Sum, as he would invite his alumni to share with us their tacit knowledge, experience and insights. These are useful information that we cannot find on the Internet or in books. Such arrangements do not just help in our learning journey, but they also allow us to form networks and promote engagement between existing students and alumni, which benefit both parties.<br/><br/>

            These get-along sessions prompted Associate Professor Cheung and a group of us to set up the MAIDT Alumni group. Later, we found out that Associate Professor Lim Kam Ming was also working to set up the NTU NIE AA with members from other programmes, such as the PhD programme and Teaching Scholars Programme. In the end, we came together to form the NTU NIE AA and I was elected to lead the team.<br/><br/>
            
            To grow the association, I think there are three important areas to focus on. First, it is to build long term relationships with the alumni, as our fundamental aim is to provide support for the alumni at different stages of their lives in learning and work. Second, we aim to build sub-committees. By having more active sub-committees, we would be able to provide more resources and greater opportunities for collaboration, as well as hold more activities for the alumni. Finally, we hope to identify the interests of the alumni so that we can organise activities based on their needs.
            </>,
            "In the coming months, we intend to communicate with the alumni on the set-up of the NTU NIE AA and encourage them to join our online platform where we can continue to engage each other. In addition, we also intend to organise activities such as talks and webinars and invite guest speakers to share their knowledge and expertise with our alumni. Through these activities, we hope to help them learn and grow and build their confidence in the NTU NIE AA."
        ],
        
        text_bubble:{
            image:GPL_body_text_bubble_01,
            width:16,
            top:4.5,
            right:20,
        }
        }
    },
    {
        image:GPL_card_img_02,
        name:"Janice Kee",
    
    body:{
        title_col:7,
        first_body_header_col:first_body_header_cols[1],
        padding_top:GPL_body_paddings[1],
        title:"Master of Arts in Professional Education (Training and Development) student",
        body_headers:
        ["Why did you decide to enroll in the Master of Arts in Professional Education (MAPE) (Training and Development) programme?",
        "How has this helped you in your career thus far?",
        "What features of the programme appealed to you?",
        "How did you balance both work and study?",
        "Please share an interesting anecdote about your programme."
        ],
        body_paragraphs:[
            "I wanted to acquire new insights into workplace learning; as well as enrich and reinforce my learning and understanding of adult learning within my workplace.",
            <>I have learned to embrace the changes in traditional teaching and learning methodology. The programme also made me rethink how I should facilitate learners to adapt and learn in today’s fast-paced,
            high-tech and challenging environment.
            <br/><br/>
            I have learned to employ different instructional/teaching methodologies to better engage my students and junior colleagues in their learning. I have also shared what I have learned with my peers so that they can, in turn, apply it to their teachings and more can benefit from it.
            </>,
            "I liked that this new programme is developed collaboratively by established academic institutions, i.e., NIE and IAL (Institute for Adult Learning), is more coursework-based (as opposed to research-based) and has its emphasis on adult workplace learning which makes it relevant and practical. The assignments also emphasised on workplace contextualisation and application. Lastly, this programme is not restricted to one profession. It allows individuals from a wide array of professions to share and learn from one another, with expert lecturers facilitating our learning, making it relevant for the different professions.",
            <>Having to balance studies with work and family was certainly not easy, but I am fortunate to receive support and encouragement from my bosses and family.
            <br/><br/>
            Also, in order to make time for my studies, I sacrificed time spent on hobbies and sleep. There are times when I had to stay up late to complete assignments and projects, but it was all worth it. Our respective course lecturers were also very kind, understanding and thoughtful, and planned assignment submissions to be on Mondays so that we can work on them during the weekends.</>,
            "My classmates would always motivate and encourage one another. As one of my classmates, Dennis Lim aptly said, “we are not here to compete for first place, but we will complete this programme together and graduate as a class”. We had a common goal and we encouraged one another along to achieve it."
        ],
        text_bubble:{
            image:GPL_body_text_bubble_02,
            width:17,
            top:6,
            right:20.5,
        }
        }
    },
    {
        image:GPL_card_img_03,
        name:<>Nursidah<br/>binte Malik</>,
    
    body:{
        title_col:8,
        first_body_header_col:first_body_header_cols[2],
        padding_top:GPL_body_paddings[2],
        title:"Master of Education (Special Education) graduate",
        body_headers:
        ["Please share with us your professional life’s journey thus far and at which point in your career did you aspire to do a higher degree programme?",
        "What attracted you to your chosen programme at NIE?",
        "How would you describe your interactions with NIE faculty members?",
        "Could you share with us some interesting facets of your programme?",
        "Would you recommend the programme at NIE to someone else? What would you say about it?"
        ],
        body_paragraphs:[
            "I started my professional career in special education as a teacher. I taught children with autism and started the arts movement in the school by founding the visual arts committee. After seven years of gaining experience and teaching practice in the classroom, I felt that I was ready to pursue new challenges by advancing my knowledge and skills in education. Embarking on the Master of Education (Special Education) programme was a good platform to broaden my perspective and understanding of the special education sector.",
            "Ten years ago, I attended the Diploma in Special Education in NIE. I learned a lot from the programme and enjoyed the learning experience. I remember the Early Childhood and Special Needs Education (ECSE) Academic Group’s faculty members were wonderfully engaging and very knowledgeable in their field. I came out of the programme as a more confident and equipped person as I strengthened and elevated my professional capacity as a teacher and classroom leader. This experience helped me decide to pursue the Master of Education (Special Education) programme with NIE because I know NIE can provide the quality education that I need.",
            "I had a great time interacting with NIE faculty members. They were very helpful, knowledgeable and approachable. It was easy to reach them by email and they were quick to respond to my questions. Most of all, I like the fact that the lecturers did not simply give me all the answers on a silver spoon but they guided me to research for relevant information and stimulated my thinking and analytical skills.",
            <>Being able to do an elective from another programme was very insightful. I did an elective from the Master of Education (Art) programme and it was a very fun experience. The elective gave me opportunities to interact and learn from dedicated educational professionals outside of special education and enriched my appreciation for multiple perspectives and approaches.<br/>

            The process of completing a dissertation made my learning journey in NIE very meaningful, though it was one of the toughest challenges I had ever attempted. It was made even harder by the fact that my mother was diagnosed with end-stage cancer a few months after I had begun. Nonetheless, doing the dissertation allowed me to utilise the theories and everything that I learned from the other courses. It gave me a wholesome learning experience. It was like a sneak peek into what it takes to be in the educational research field. I was also lucky to have Dr Ailsa Goh as my supervisor because she guided and taught me well with such kind understanding, especially given my difficult situation. I would not have been able to complete my work without her professional guidance and support. At the end of it all, I am richer from the experience and completing it made it even more meaningful as I am able to dedicate my work to my mother who is a large influence in my personal and professional life.</>,
            "If your aim is to gain insight into research and developments in special education, then I would highly recommend it. The programme provides its students with a good understanding of many aspects of special education in Singapore such as the challenges faced by students, staff and families. It helps you develop critical inquiry and deepen your understanding beyond the classroom and even on a global scale."
        ],
        text_bubble:{
            image:GPL_body_text_bubble_03,
            width:16.5,
            top:5,
            right:19.5,
        }
        }
    },
    {
        image:GPL_card_img_04,
        name:<>Dr Aliamat<br/>Omar Ali</>,
    body:{
        title_col:6,
        first_body_header_col:first_body_header_cols[3],
        padding_top:GPL_body_paddings[3],
        title:"PhD graduate",
        body_headers:
        ["Which aspect of the programme and courses do you like the most?",
        "Share with us a memorable experience you had during your time at NIE?",
        "Describe your relationship with the NIE faculty members.",
        "At what point in your career did you consider pursuing a higher degree education?",
        "Describe the factors that led you to choose NIE?",
        "Will you recommend NIE’s programmes to your friends?",
        "Was it easy to integrate into the learning environment at NIE? (for international students)"
        ],
        body_paragraphs:[
            "I like the courses which I took during the first part of the programme as I was able to interact and work closely with local teachers who were also pursuing their postgraduate studies. There is also an abundance of seminars/short courses and top class facilities offered to all students.",
            "One of the memorable experiences that stuck with me to this day was when I attended another student’s confirmation seminar. To me, the presentation was well prepared. However, the examiners had unanimously ordered the candidate to re-write the proposal and this impacted my perspectives towards my confirmation seminar and final write-ups. This experience has somehow shaped me to become a more critical researcher than I used to be.",
            "I’m truly inspired by the work ethics of both the academics and non-academics. There were times when I had to approach teaching staff for matters pertaining to my research and they were all willing to guide me out of my intellectual misery and see me succeed. The hospitality given to me by the support staff was also great.",
            "I joined Universiti Brunei Darussalam (UBD) in 2004, and I knew that sooner or later, I needed to pursue my PhD. While I was doing my Master’s at University of York, my wife was reading her Master’s at NIE. It was then that I had the opportunity to visit my wife in Singapore and NIE. I was instantly hooked by NIE’s international reputation and the quality of expertise offered by its Malay Languages and Cultures Department. I was very determined to apply for a place at NIE as soon as I got tenured at UBD.",
            "The main factor is its reputation. I like to challenge myself. At that time, no local academic from my faculty graduated from a Singapore university. Almost everyone that I sought advice from encouraged me to go to Europe, or to Australia or even the US as the system is ‘simpler’ and is popular amongst the Bruneians. The feedback about Singapore that I was given were that Singapore universities are tougher. And this made me think: isn’t tough good? Moreover, most education seminars in Brunei had keynote speakers from Singapore, which could be because Brunei and Singapore have a lot in common; one of which is the education systems.",
            "Of course. In fact I did.",
            "It was somehow a smooth experience for me. My classmates were very helpful and the atmosphere was very positive. Even when it started to get tougher when I began writing my research proposal, my supervisors continued to be supportive, and so did my course-mates. Although I spent most of my time at my workstation, networking with other students during breaks was a norm. We would spend time together and share our thoughts especially about our research."
        ],
        text_bubble:{
            image:GPL_body_text_bubble_04,
            width:23,
            top:2.5,
            right:20,
        }
        }
    },
    // {
    //     image:GPL_card_img_05,
    //     name:"Dr Gog Soon Joo",
    
    // body:{
        
    //     title:"Master of Education (Special Education) graduate",
    //     body_headers:
    //     ["What are you currently working as?",
    //     "What attracted you to a higher degree programme at NIE?",
    //     "How would you describe your interactions with NIE faculty members?",
    //     "Could you share with us more on the research that you did? Did the research yield the desired outcome that you hoped for?",
    //     "What was the best part of being a student at NIE?",
    //     "Has the programme been beneficial to you in bringing forth changes in your organisation?"
    //     ],
    //     body_paragraphs:[
    //         <>My current portfolio at the Singapore Workforce Development Agency (WDA) is Group Director of Training Partners Group and Chief Research Officer.
    //         <br/>
    //         Prior to joining the programme, I had three years of experience in workforce development and I felt that I needed to expand my knowledge in order to contribute to the strategic focal of continuing education and training (CET) in Singapore. The practitioner-researcher based higher degree seemed to be the path for me since my ultimate aim is to hone my professional practice.</>,
    //         "In 2006, I learnt from Professor Lee Sing Kong that NIE will be partnering the Institute of Education (IOE), UK in launching the Doctor in Education (EdD) in Singapore. Other than the fact that the degree will be awarded by two prestigious institutes of education, the programme allows me to complete my coursework in Singapore, which was ideal given that I had to juggle both work and family. Furthermore, NIE’s academic team has long established research in Singapore’s education and political economy arena. For example, Professor Gopinathan and Professor Jason Tan, whose works are relevant to my work in WDA.",
    //         "At the initial phase of our learning journey, my coursemates and I had to re-acquaint ourselves with critical thinking, reading and writing. The academic members were most patient and nurturing throughout the journey. I was most fortunate to be assigned Associate Professor Lee Yew Jin as my supervisor. I would not be able to complete my EdD journey without his guidance.",
    //         "My EdD thesis focused on the critical review of the Singapore’s continuing education and training system. The research journey was the best learning experience I have ever had. I was exposed to interdisciplinary theories which include education, sociology, political economy and praxeological analysis. The learning journey has developed my critical thinking and analytical capability which I deem as fundamental competencies for the 21st century.",
    //         "My coursemates who became my best friends; my professors whom I'm still in touch with and of course, the library, especially the online resources which provided access to a wide range of literature.",
    //         "I continue to participate in research projects and involve in evident-based policy and programmes design which I would attribute to the learning I have gained from the EdD programme."
    //     ]
    //     }
    // }
]

export const professorship_data=[
    {
        image:prof_card_img_01,
        name:"CJ Koh",
        title:"Professorship in Education",
        intro:<>The CJ Koh Professorship hosts a world-renowned professor in education annually. The professorship was established in 2006 through a donation by the late Mr Ong Tiong Tat, the executor of Mr Koh Choon Joo’s (CJ Koh) estate. Mr Koh was a prominent lawyer and philanthropist.
        <br/><br/>
        Since 2011, CJ Koh Professorial public lectures and discussions have been documented in the CJ Koh Professorial Lecture Series. The ideas and insights arising from the professorial visits are shared with the other wider community through these reports, further enriching the discourse on education research, policy and practice.</>
    },
    {
        image:prof_card_img_02,
        name:"E. W. Barker",
        title:"Professorship in Physical Education and Sports Science",
        intro:<>Since its inception in 2002, the E.W. Barker Professorship has facilitated lively and thoughtful exchanges between distinguished professors of physical education and sports science, and teachers, coaches, athletes and academics in Singapore. The professorship is also aimed at promoting research collaborations among NIE, local sporting bodies and overseas universities. It was established as a tribute to Mr E. W. Barker, a pioneer Cabinet minister and avid sportsman who was also the first President of the Singapore National Olympic Council.</>
    },
    {
        image:prof_card_img_03,
        name:"Lee Sing Kong",
        title:"Professorship in Early Childhood Education",
        intro:<>The Lee Sing Kong Professorship was established in 2017 in honour of Professor Lee Sing Kong. Professor Lee was a former Director of NIE, passionate academic and visionary educator, who was highly esteemed for his contributions to Singapore’s teacher education. The professorship welcomes outstanding professors of international standing in the field of early childhood and early childhood education to engage with the early childhood community in Singapore through public lectures, seminars and workshops.</>
    },
    {
        image:prof_card_img_04,
        name:"Dr Ruth Wong ",
        title:"Professorship in Teacher Education",
        intro:<>The Dr Ruth Wong Professorship was inaugurated in 2015 in memory of Dr Ruth Wong Hie King, Founding Director of the Institute of Education (NIE’s predecessor). It welcomes renowned scholars to share their knowledge and advance the teacher education experience and expertise in Singapore. Another objective is to enhance cooperation between the local and global education communities.</>
    },
]


export const research_data={
    0:{
        header:<>OUR RESEARCH</>,
        subheader:<>Discover the NIE’s research foci</>,
        bHeaders:["Introduction"],
        bParas:[
        <>Research at the NIE strengthens the nexus between research, theory and practice in education. It helps to inform education policies and also enhances learning and teaching in both the NIE and schools.</>],  
        quotes:[
            {
                quote:<>“Education research seeks answers to the everyday problems of teaching and learning. It also affirms the value we place on education.”</>,
                author:<>~ Professor Christine Goh, NIE Director (2018–Present)</>
            }
        ]
    },
    1:{
        header:<>OUR RESEARCH</>,
        subheader:<>Discover how the NIE’s research enables you to be a better educator </>,
        bHeaders:["Introduction",<>How does the NIE’s research <br/>enhance learning and teaching?</>],
        bParas:["Research at the NIE strengthens the nexus between research, theory and practice in education. That is why it helps to inform education policies and also enhances learning and teaching in both the NIE and schools."],
        vidUrls:["https://www.youtube.com/embed/njxVtoS_xz8"]
    },
    2:{
        header:<>OUR RESEARCH</>,
        subheader:<>Discover the NIE’s research foci</>,
        bHeaders:["Introduction","Where is the NIE’s research making impact?"],
        bSubheaders:["Explore the NIE’s research projects."],
        bParas:["Research at the NIE strengthens the nexus between research, theory and practice in education. It is underpinned by our 4-Life learning framework – Life-long, Life-deep, Life-wide and Life-wise. Exciting new areas of research include the science of learning in education and artificial intelligence for education."],
        impactsShort:[
            "Bilingualism and Biliteracy",
            "Child Development",
            "Classroom",
            "Humanities",
            "Learning Environments",
            "Mathematics",
            "Preschool",
            "Teacher Education",
            "Wellbeing and Values"
        ],

        impactsLong:[
            ["Bilingual and Biliteracy Development"],
            ["Bedok-Punggol Ongoing Singaporean Study Beginning in Infancy: Twenty-First Century Skills, Individual Differences, and Variance in the Environment (BE POSITIVE study)"],
            ["CORE Research Programme"],
            ["Signature Programmes in Humanities: The Sustainability Learning Lab & The Historian’s Lab"],
            ["Collaborative Video Annotation and Analytics Environment (CoVAA)"],
            ["Constructivist Learning Design for Singapore Secondary Mathematics Curriculum","A Study of the Enacted School Mathematics Curriculum"],
            [<>Singapore Kindergarten Impact Project (SKIP)</>],
            ["Building an Evidence Base for Teacher Education and Professional Development"],
            ["Nurturing Positivity and Resilience in Schools"]
        ],
        authors:[
            ["Dr Beth O’Brien and Dr Wu Chiao-Yi"],
            ["Dr Anne Rifkin-Graboi"],
            ["Dr Dennis Kwek and Dr Wong Hwei Ming"],
            [<>Dr Mark Baildon (Principal Investigator);<br/> Dr Suhaimi Afandi, Dr Ivy Lim, Dr Tricia Seow & Dr Kim Irvine (Co-PIs)</>],
            ["Dr Elizabeth Koh and Dr Jennifer Tan"],
            ["Associate Professor Lee Ngan Hoe",
            <>Prof Berinderjeet Kaur (Lead Principal Investigator); <br/>
            Dr Toh Tin Lam (Co-Lead PI);<br/> Dr Lee Ngan Hoe (PI for Study 1);<br/> Dr Leong Yew Hoong (PI for Study 2)</>],
            ["Dr Ng Ee Lynn"],
            ["Professor Low Ee Ling"],
            [<>Dr Imelda Caleon (Principal Investigator); <br/>Dr Tan Chee Soon, Dr Jennifer Tan, Assoc Prof Ronnel B. King, <br/>
            Assoc Prof Gregory Arief D. Liem and Dr Pamela Onishi (Co-PIs); <br/>Dr Rachel Lam, Mr Ong Chin Leng and
            Mdm Juli Anna Mohamad Aris (Collaborators)</>]
        ],
        studies:[
            ["Experimental"],
            ["Baseline"],
            ["CORE"],
            ["Developmental"],
            ["Intervention"],
            ["Intervention","Baseline and Programmatic"],
            ["Exploratory"],
            ["Baseline"],
            ["Intervention"]
        ],
        why:[
            ["Effective bilingualism is the cornerstone of Singapore’s education system. MOE has adjusted the language curricula to meet the needs of emergent bilingual students, and foster their development as English-knowing individuals practising their heritage language as a living language. The aim: students who can competently use two languages, to contribute to a global citizenry able to understand and communicate across cultures."],
            ["By school entry children already display individual differences in 21st century skills, and these early life differences may predict later mental health, educational success, well-being, and even physical health. A better understanding of the development of individual differences within the local context will enable the creation of more targeted and cost-effective prevention and intervention programs at appropriate stages of early development. In addition, the fact that the BE POSITIVE study is a collaboration between educational researchers and community health care providers is an important first step in creating greater interdisciplinary engagement, increasingly recognised as important to child development and societal well-being."],
            ["MOE has continually adapted the education system to foster the holistic and cognitive development of Singapore students. The aim: to prepare our learners to thrive in an increasingly uncertain and fast-changing future shaped by globalisation, changing demographics, technological advancements and unforeseeable crisis events."],
            ["This study aimed to develop and implement inquiry-based pedagogical, curriculum and ICT resources to support inquiry-based learning in geography and history. Using these resources, professional development experiences were designed to enhance teacher understandings of disciplinary inquiry and to develop competencies necessary to enact authentic disciplinary inquiry in classrooms."],
            [<>Video-based learning is increasingly being used to promote discussion and reflection among learners. However, to foster greater conceptual understanding and 21st century learning outcomes, CoVAA was developed to expand opportunities for learners and their instructors to collaboratively view, annotate, and engage in rich dialogue and reflective discussions around video content.
            <br/><br/>
            Based on a nested framework of computer-supported collaborative learning, assessment for learning and dialogic teaching, CoVAA is designed with two key techno-pedagogical affordances: (i) timepoint-based collaborative video annotation and discussion with metacognitive scaffolds and (ii) dynamic learning analytics dashboards for formative feedback.</>],
            ["In the recent Singapore secondary mathematics curriculum by the Ministry of Education, teachers are encouraged to embark on pedagogical approaches that, on top of the mastery of the necessary mathematics content, allow for their students to experience the discipline of mathematics deeply and develop 21st century competencies. These approaches should afford students with opportunities to not only communicate the reasoning of mathematical concepts, apply them to new contexts, but also to develop critical and inventive thinking skills, collaborative skills, and confidence and interest in mathematics. In response to these recommendations, we proposed a pedagogical approach, called Constructivist Learning Design (CLD), which Singapore secondary mathematics teachers could leverage to better support these learning experiences. In addition, the study aims to build teachers’ capacity in implementing learning designs that build on students' prior knowledge, and to ensure that such a design can be sustained in the mathematics classroom beyond the research.",
            <>Past studies, carried out by the CORE 2 team at NIE, have shown that performative tasks shape much of mathematics classroom activities and discourse in Singapore secondary schools. As the sample for these studies was a stratified random one, the findings are general. It is not possible to infer from these findings if the performative orientation also pervades the classrooms of our experienced secondary mathematics teachers. In addition, these findings could not explain the success of students from Singapore in PISA. 
            <br/><br/>
            Therefore, there was a need to examine in a comprehensive manner how experienced secondary mathematics teachers enact the school mathematics curriculum, so that we have knowledge about the upper bound of pedagogies adopted by secondary mathematics teachers in Singapore schools.</>],
            ["Previous research based overseas had investigated the impact of early childhood education on success and wellbeing in later schooling and adulthood. A large-scale, longitudinal study focused on local circumstances was needed."],
            ["The suite of longitudinal studies was conceptualised to provide an evidence base to inform the enhancement, design and delivery of teacher education programmes in Singapore. Two recent projects have been added to this suite, moving from a focus on initial teacher preparation to one on beginning teacher mentoring and teachers’ career-long growth and development."],
            ["The Nurturing Positivity and Resilience (NPR) projects were conceived given the importance of promoting well-being, an important correlate of learning and future life outcomes, among adolescents who are at higher risk for a wide range of maladaptive outcomes compared to adults and children."],
        ],
        what:[
            [<>The processes and mechanisms for understanding different languages may reveal insights into different approaches for teaching early literacy, taking into consideration the types of languages and scripts that emerging bilinguals are to acquire. An emphasis on lexical level 
            (e.g., character recognition) or sublexical level (phonemic decoding) skills might be more beneficial for non-alphabetic and alphabetic languages, respectively. On the other hand, learning English script might be impacted cross-linguistically by the other language a particular student is learning. 
            <br/><br/>
            These insights will further guide prediction, diagnostic assessment, and intervention (e.g., multimodal) for bilingual and biliteracy development, which are examined in our ongoing studies with young children.</>],
            [<>Children are not born into classrooms. Prior to school entry they have already had a myriad of experiences in their homes and communities. These experiences are likely to begin shaping their “twenty-first century, or “non-domain specific” skills including abilities in working memory, emotional perception and regulation, shifting and inhibition. In turn, these skills may influence both health and educational outcomes, the latter including “domain specific” competencies in language, literacy and mathematics. BE POSITIVE aims to understand the development of these domain general skills, and their impact on future functioning. It also considers how different forms of experience and experiential settings (e.g., home and preschool), independently and interactively influence child development. The BE POSITIVE study will provide important information concerning how and why individual differences unfold within Singapore. This information may prove useful to the planning of targeted intervention and prevention strategies, across educational and health domains. In addition, BE POSITIVE is a key step towards building common vocabularies and knowledge bases across community health and educational sectors in Singapore. This form of education-health collaboration is increasingly recognized as important to promoting societal well-being.</>],
            [<>The Singapore education system has evolved from being merely efficient and effective. To prepare students for the demands of the 21st century, it now includes more pathways, and promotes inquiry and innovation, while continuing to emphasise values and content mastery. New initiatives such as the SkillsFuture for Educators, the Student Learning Space, curriculum revisions and teacher education innovations, have continued to help prepare teachers and learners for the future. 
            <br/><br/>
            CORE has provided objective baseline data to inform MOE’s curriculum and policy reviews. The findings are also used to develop professional development, new pedagogical strategies, teacher education programmes, and diffused to teachers through workshops and research reports. When teachers gain deeper insights into what they are doing, they are better able to align their practice to the goals of systemic reforms.</>],
            [<>The Singapore education system has evolved from being merely efficient and effective to providing more authentic and meaningful opportunities through student-centred, inquiry-based learning experiences. To prepare teachers for inquiry-based classrooms requires that they understand disciplinary inquiry methods and processes and are able to enact these in ways that are accessible and appropriate for their students. To prepare students for the demands of the 21st century, developing classroom cultures of inquiry where students are engaged in authentic disciplinary work, focused on real-world problems and questions, helps them develop core 21st century competencies and dispositions.</>],
            [<>CoVAA for schools has received much interest from local and overseas institutions. For instance, an additional trial was conducted in 2018 at the request of one of the partner schools. Additionally, an international university pilot was conducted at the request of an Associate Professor and his master’s students at Seoul National University (SNU) (which the project team supported remotely despite this being beyond the original scope of the project). The system was also nominated for Best Technical Design Paper Award at the 26th International Conference on Computers in Education (ICCE).
            <br/><br/>
            CoVAA was augmented into CoVAAPD for blended teacher PD. CoVAAPD has also received much interest from the teaching community with ongoing plans with PESTA, AST, for wider implementation and usage.</>],
            [<>The CLD units were implemented in 9 secondary schools, by 27 teachers, in 34 classes (795 students) and for 123 lessons. To date, 31 mathematics teachers underwent professional development, with 14 of them being actively involved in the Networked Learning Community. The CLD research was shared via various platforms to reach out to the mathematics education fraternity. Despite the COVID situation, we managed to (i) reach out to more than 200 teachers via the CLD Seminar held in conjunction with the Centre for Teaching and Learning Excellence (CTLE) @ Yusof Ishak Secondary School, and (ii) share our research via the “MOE Research Forum for Research and Practice” that was attended by 400 participants, including MOE master teachers and specialists. A book chapter on how constructivism was realised in the classroom via the CLD was also published in 2020.
            <br/><br/>
            The project elucidates the tripartite partnership among policy makers, practitioners and researchers as a translational mechanism in diffusing and sustaining pedagogical innovations. </>,
            <>This project has contributed towards knowledge of mathematical instructional practices of teachers in Singapore secondary schools.
            <br/><br/>
            Amongst the numerous publications, two notable ones are:
            <br/><br/>
            <span className="font-weight-500">Twelve Questions on Mathematics Teaching – Snapshots from A Study of the Enacted School Mathematics Curriculum in Singapore:</span> This 108-page book answers 12 key questions on mathematics teaching for teachers in Singapore schools and elsewhere. A pdf copy of the book is downloadable at: www.tinyurl.com/Enact-12Q 
            <br/><br/>
            <span className="font-weight-500">Mathematical Instructional Practices in Singapore Secondary Schools:</span> An academic book, comprising 17 chapters, written in a clear and engaging style describes and discusses the ‘DNA’ of mathematics classrooms as practised in Singapore. The book is published by Springer.</>],
            [<>The findings from SKIP offer insight into the different factors influencing a child’s early learning and development and how we can further enhance the quality of early childhood education to ensure a good start for every child. For example:
            <br/><br/>
            <ul>
                <li>The SKIP Professional Development Project Team has developed training videos and resources for the National Institute of Early Childhood Development (NIEC) to improve teacher-child interactions in the preschool classrooms.</li>
                <br/>
                <li>The SKIP team is also contributing to an ongoing review of the Nurturing Early Learners (NEL) Curriculum Framework, developed by MOE to guide teaching and learning of children aged 4 to 6.</li>
            </ul>
            The SKIP team has been actively sharing their findings with different stakeholders, including early childhood education practitioners, researchers and policymakers, and will continue to share them with in-service teachers. This will ensure that Singapore’s early childhood educators receive theoretical and practical knowledge based on up-to-date research in the local context.
            </>],
            [<>
            These findings have led to the enhancement of teacher education programmes and teacher selection policies. The following recommendations were made to mentoring practices and provided insight into how policies and practices could be changed to effectively engage teachers at different stages in their career and keep them committed career-long.
            <br/><br/>
            <span className="subheader">An Exploratory Study of Beginning Teacher Mentoring Practices in Singapore</span>
            <br/>
            Recommendations were made for a system-level mentoring programme beyond the beginning teaching years that potentially lifts the professional practice of the teaching fraternity for quality teaching and learning.
            <br/><br/>
            <span className="subheader">Building an Evidence-base to Support Teacher Growth: A Career-long Perspective (Phase I)</span>
            <br/>
            Recommendations were made to policymakers and school leaders to focus on the professional growth of teachers and enhance their professional relationships in order to retain and develop teachers career-long.
            </>],
            [<>The NPR projects have produced a wealth of resources and findings that have shown a strong potential to impact research, policy and practice, both locally and internationally. Some of the key indicators of the NPR projects’ impact include education packages developed that were adapted by at least 10 Singapore schools and used by about 4000 students; these packages also served as the bases for the development of resource materials used in the Philippines. A professional development course was also developed based on the resources and empirical findings generated from the projects, which has had two runs at NIE. The findings drawn from this project have been presented in 3 keynote and 9 conference presentations, 34 invited seminars and workshops, 3 journal articles, 4 book chapters and 9 research reports and articles.</>]
        ]

    }
}