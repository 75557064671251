import React from "react"

import {facebook_icon,youtube_icon,instagram_icon} from "../../../static/images";

import {isMobile} from "react-device-detect"

const icons=[
    {
        icon:facebook_icon,
        link:"https://www.facebook.com/NIESingapore/"
    },
    // {
    //     icon:twitter_icon,
    //     link:"https://twitter.com/oer_nie?lang=en"
    // },
    {
        icon:youtube_icon,
        link:"https://www.youtube.com/user/NIESingapore"
    },
    // {
    //     icon:linkedIn_icon,
    //     link:"https://www.linkedin.com/company/nie/"
    // },
    {
        icon:instagram_icon,
        link:"https://www.instagram.com/niesingapore/?hl=en"
    },
]


export default function SocialMedia(props){

    const {style}=props;

    const iconLists=icons.map((item,index)=>{
        return (
            <a className="m-2" href={item.link} target="_blank" rel="noreferrer noopener" key={`media-${index}`}><img className="media-icon" alt="mediaicon" src={item.icon}></img></a>
        )
    })

    
    return (
        <div style={style} className="social-media-wrapper">
            {iconLists}
        </div>
    )
    
    
}