import React,{useEffect} from "react";


import {} from "../../static/images"


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"

import Overlay from "./components/overlay"

import {isMobile} from "react-device-detect";

const header=<><i>PAPILIONANDA</i> NIE SG</>
const subheader=<>An orchid to call our own</>
const bodyHeader_0=<>The NIE NTU Orchid</>
const bodyPara_0=(<> The NIE NTU orchid, <i>Papilionanda</i> NIE SG, commemorates the NIE’s 70th anniversary and celebrates our lasting impact in nurturing educators in Singapore. It reminds us that every Singapore teacher’s professional journey begins here at the NIE.</>)

const vidTitle_0="𝙋𝙖𝙥𝙞𝙡𝙞𝙤𝙣𝙖𝙣𝙙𝙖 NIE SG | The NIE NTU Orchid";
const vidUrl_0="https://www.youtube.com/embed/zfdt6mJvWXs"

export default function Papi(props){
    const {onCloseCallback,onOpenCallback,show,setShow}=props;

    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
    

    
        return (<div className={`${isMobile?"papi-container-mobile":"papi-container"} animate__animated animate__fadeIn animate__faster`}>
        
        <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100">   
                <BodyHeader header={bodyHeader_0}></BodyHeader>    
            </Row>
                    
            <Row className="w-100">  
                <BodyParagraph paragraph={bodyPara_0}></BodyParagraph>
            </Row>

            <Row className="w-100">
                
                <Col xs={{span:10,offset:1}}>
                    <div className="aspect-ratio-16-9">
                        <iframe title={vidTitle_0} className="YTB-video" src={vidUrl_0} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </Col>
                
            </Row>
        </Overlay>

    </div>)
    
};
