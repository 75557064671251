import React,{useEffect} from "react";


import Row from 'react-bootstrap/Row'



import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"

import {isMobile} from "react-device-detect";


import Overlay from "./components/overlay"

const header=<>OUR GLOBAL CONNECTIONS</>
const subheader=<>Check out our extensive connections to leading education<br/> institutions around the world</>;

const externalLink="https://www.niei.nie.edu.sg/";
const bodyHeader_0=(<>The NIE and the World</>)
const bodyPara_0=[
    <>The NIE continues to establish and deepen ties with strategic partners locally and globally.
    <br/><br/>
    Thanks to these connections, our students can gain international experience and perspectives at overseas educational institutions and organisations. Our researchers also have the opportunity to collaborate with their peers from around the world.
    <br/><br/>
    The NIE also shares its knowledge with other institutions around the world through the NIE International, our consultancy and training private arm. Find out <a className="theme-link" href={externalLink} target="_blank" rel="noreferrer noopener">more</a>.
    </>,

    <>
    The NIE continues to establish and deepen ties with strategic partners locally and globally.
    <br/><br/>
    Thanks to these connections, you will have many overseas opportunities as a student teacher. Think exchange programmes, international practicums and even research collaborations.
    <br/><br/>
    The NIE also shares its knowledge with other institutions around the world through the NIE International, our consultancy and training private arm. Find out <a className="theme-link" href={externalLink} target="_blank" rel="noreferrer noopener">more</a>.
    </>,

    <>
    The NIE continues to establish and deepen ties with strategic partners locally and globally.
    <br/><br/>
    Thanks to these connections, you will have opportunities to collaborate with reputable educational institutions and organisations around the world.
    <br/><br/>
    The NIE also shares its knowledge with other institutions around the world through the NIE International, our consultancy and training private arm. Find out <a className="theme-link" href={externalLink} target="_blank" rel="noreferrer noopener">more</a>.
    </>,
    ]


export default function Programmes(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;

    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }

        
        return(<div className={`${isMobile?"connection-container-mobile":"connection-container"} animate__animated animate__fadeIn animate__faster`}>
    
        <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100">   
                <BodyHeader header={bodyHeader_0}></BodyHeader>    
            </Row>
                    
            <Row className="w-100">  
                <BodyParagraph paragraph={bodyPara_0[persona]}></BodyParagraph>
            </Row>
        </Overlay>
           
        </div>);
};
