import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function SubmissionResult(props){

    const {success,show,submit}=props;

    const headers=["Thank You!","Something went wrong!"]
    const statement=["Your submission has been received.","Let’s give this another try."]

    return (
        <Row  className={` justify-content-center w-100 mt-5 ${show?"d-flex":"d-none"}`}>
            <Col className="submission-result-wrapper" xs="auto">
                <h1 className="main-message">{headers[success?0:1]}</h1>
                <p className="statement">{statement[success?0:1]}</p>
                <button onClick={()=>{submit()}} style={{display:success?"none":""}} className="theme-btn-auto">Try Again</button>
            </Col>
        </Row>
    )
}