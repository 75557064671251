var MATTERPORT_SDK_KEY;
var API_URL_FEEDBACK;
var API_URL_EMAIL_INFO;
var API_URL_SESSION_INFO;

var DOMAIN;
var BASENAME;
var ROOT_URL;

const MATTERPORT_URL ="https://my.matterport.com/show/?m=qBQpeQxiRDN&help=0&play=1&qs=1&mls=2&dh=1&hr=0";

DOMAIN=window.location.host;

if (process.env.NODE_ENV === 'development') {
    API_URL_FEEDBACK=process.env.REACT_APP_DEV_API_URL_FEEDBACK;
    API_URL_EMAIL_INFO=process.env.REACT_APP_DEV_API_URL_EMAIL_INFO;
    

    MATTERPORT_SDK_KEY=process.env.REACT_APP_DEV_MATTERPORT_SDK_KEY;
    
    
    BASENAME=process.env.REACT_APP_DEV_BASENAME;
    API_URL_SESSION_INFO=process.env.REACT_APP_DEV_API_URL_SESSION_INFO;
    
    
}
else{

    API_URL_FEEDBACK="https://"+DOMAIN+process.env.REACT_APP_PROD_API_URL_FEEDBACK;
    API_URL_EMAIL_INFO="https://"+DOMAIN+process.env.REACT_APP_PROD_API_URL_EMAIL_INFO;
    API_URL_SESSION_INFO="https://"+DOMAIN+process.env.REACT_APP_PROD_API_URL_SESSION_INFO;
    MATTERPORT_SDK_KEY=process.env.REACT_APP_PROD_MATTERPORT_SDK_KEY;
    
    BASENAME=process.env.REACT_APP_PROD_BASENAME;
    
}
ROOT_URL=DOMAIN+BASENAME+"";

export {API_URL_FEEDBACK,
    API_URL_EMAIL_INFO,
    MATTERPORT_SDK_KEY,
    DOMAIN,BASENAME,
    MATTERPORT_URL,API_URL_SESSION_INFO}




window.DOMAIN=window.location.host  //window.location.hostname