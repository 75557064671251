import React from "react";
import {white_cross,black_cross} from "../../../static/images"
import {
    isMobile
  } from "react-device-detect";

export default function CloseButton(props){
    const {onClick,style}=props

    if(!isMobile){
        return(<div onClick={()=>{onClick()}}  style={style} className="close-btn-wrapper clickable">
            <img className="cross" alt="corss" src={white_cross}></img>
        </div>);
    }
    
    return (
        
        <img onClick={()=>{onClick()}} className="close-btn-mobile clickable" alt="corss" src={black_cross}></img>
        
    )
    
    
};
