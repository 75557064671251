import React,{useState,useEffect} from "react";


import {triangle} from "../../static/images"


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import HeaderSection from "./components/header_section"
import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import CloseButton from "./components/close_button"

import Overlay from "./components/overlay"

import {GPL_data as data} from "../store"

import {isMobile} from "react-device-detect";

const header=(<>Graduate Studies and<br/> Professional Learning (GPL)</>)
const subheader=<>Find out how we can support your lifelong learning</>;

const bodyHeader_0=<>Introduction</>

const content={
    bodyPara_0:[
        <>Want to continuously upskill and deepen your competencies? At the NIE, you can do that and more. We offer rigorous and flexible professional learning and modular graduate courses that are stackable towards micro-credentials, with course credits transferable to relevant higher degrees.</>,
        <>Want to continuously upskill and deepen your competencies? At the NIE, you can do that and more. We offer rigorous and flexible professional learning and modular graduate courses that are stackable towards micro-credentials, with course credits transferable to relevant higher degrees.</>,
        <>Wish to become an expert in your field? Want to create knowledge that makes positive impact on the world? At the NIE, you can do that and more. We offer graduate studies by coursework and research leading to an MA, MSc or PhD in the areas of arts, education, humanities, physical education and science. Graduate students can engage in cutting-edge research and pedagogy courses at various research centres and labs in the NIE.</>
    ],
    bodyHeader_1:[
        "Why choose NIE GPL?",
        "Hear about GPL from our alumni",
        "What is the research experience at the NIE like?"
    ],
    bodyPara_1:[
        <>Learn why GPL is the right choice for many.</>,
        "",
        "Hear about it from our researchers and graduate students."
    ],
    vidUrl_0:[
        "https://www.youtube.com/embed/DHxamQ7jlw0",
        "",
        "https://www.youtube.com/embed/uJqsYuccotA"
    ],
    vidTitle_0:[
        "GPL Infographic",
        "",
        "Research Studies at NIE"
    ]
}

export default function GPL(props){
    const {onCloseCallback,onOpenCallback,persona,show,setShow}=props;

    const [selected, setSelected] = useState(0);

    const cards=data.map((item,index)=>(
    <div onClick={()=>{setSelected(index)}} key={`card-${index}`} className={`${index===0?"round-corners-left":"ml-2"}  ${index===data.length-1?"round-corners-right":""} ${index===selected?"active":""} card-btn clickable`}>
        <img alt={item.name} src={item.image}></img>
        <span className="mt-1 name">{item.name}</span>
    </div>))

    var body=reloadBody();

    var onClose=()=>{
        setSelected(0);
        setShow(false);
        
    }

    function reloadBody(){

        var body_parts=data[selected].body.body_headers.map((item,index)=>{

            var headerCol=10;

            if(index===0){
                headerCol=data[selected].body.first_body_header_col
            }

            return (<section className="neutralize-row-padding" style={{marginTop:index===0?"0px":"30px"}}  key={`body-part__${index}`}>
                <Row >
                <Col  xs={headerCol}>
                    <h4 className="body-header">{data[selected].body.body_headers[index]}</h4>
                </Col>
                </Row>
                
                <Row>
                <Col  xs={11}>
                    <p className="body-paragraph">{data[selected].body.body_paragraphs[index]}</p>
                </Col>
                </Row>
            </section>)
        });



        return (
        <Col xs={12}  className="body-container">
            <img className="photo" alt="pic" src={data[selected].image}></img>
            <img className="text-bubble"  style={{width:`${data[selected].body.text_bubble.width}%`,top:`${data[selected].body.text_bubble.top}%`,right:`${data[selected].body.text_bubble.right}%`}} alt="pic" src={data[selected].body.text_bubble.image}></img>
            <Row>
            <Col xs={12}>
                <h1 className="name">{data[selected].name}</h1>
            </Col>
            </Row>
            <Row>
            <Col  className="mb-5" xs={data[selected].body.title_col}>
                <p className="title">{data[selected].body.title}</p>
                <div className="underline"></div>
            </Col>
            </Row>

            <Row style={{paddingTop:data[selected].body.padding_top}}>
            {body_parts}
            </Row>
        </Col>
        )
    }

    useEffect(() => {
        

        
    }, [selected]);

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }
    

    
        return(<div className={`${isMobile?"GPL-container-mobile":"GPL-container"}  animate__animated animate__fadeIn animate__faster`}>
        

        <Overlay  header={header} subheader={subheader} onClose={onClose}>
           
                    <Row className="w-100 ">
                        <BodyHeader  header={bodyHeader_0}></BodyHeader>
                    </Row>
                    <Row className="w-100 ">
                        <BodyParagraph  paragraph={content.bodyPara_0[persona]}></BodyParagraph>
                    </Row>
                    

                    {
                        persona===0?
                        <>
                            <Row className="w-100 ">
                                <BodyHeader  colClass="mt-4" header={content.bodyHeader_1[persona]}></BodyHeader>
                            </Row>
                            <Row className="w-100 ">
                                <BodyParagraph  paragraph={content.bodyPara_1[persona]}></BodyParagraph>
                            </Row>
                            <Row className="w-100">
                                <Col xs={{span:10,offset:1}}>
                                    <div className="aspect-ratio-16-9">
                                        <iframe title={content.vidTitle_0[persona]} className="YTB-video" src={content.vidUrl_0[persona]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </>:<></>
                    }

                    {persona===1?
                    <>
                        <Row className="w-100 ">
                            <BodyHeader  colClass="mt-4" header={content.bodyHeader_1[persona]}></BodyHeader>
                        </Row>
                        <Row className="w-100 ">
                            <Col xs={{span:10,offset:1}} className="d-flex mt-3 justify-content-center">
                                {cards} 
                            </Col>
                        
                        </Row>

                        <Row className="w-100 d-flex justify-content-center">
                            <Col xs={12}  className="mt-4 d-flex flex-column">
                                <div style={{width:`${10/12*100/data.length}%`,transform:`translate(${selected*100+50}%,3px)`,left:`${1/12*100}%`}} className="triangle-wrapper">
                                    <img alt="triangle" src={triangle}></img>
                                </div>
                                
                                <Row style={{width:isMobile?"100vw":"",marginLeft:isMobile?"-65px":""}}>
                                {body}
                                </Row>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        
                    </>}
                    
        </Overlay>
        
    </div>);
    
    
};
