import React from "react"
import Col from 'react-bootstrap/Col'


export default function QuoteSection(props){
    const {xs={span:10,offset:1},md=undefined,lg=undefined,colClass="",hClass="",quote="",author=""}=props;
    
    return (
        <Col xs={xs} md={md} lg={lg} className={`${colClass}`}>
            <div className="quote-wrapper">
                <p className="quote">
                    {quote}
                </p>
                <span className="author">
                    {author}
                </span>
            </div>
        </Col>
    )
    
    
}