import React,{useEffect} from "react";

import Row from 'react-bootstrap/Row'



import BodyHeader from "./components/body_header"
import BodyParagraph from "./components/body_paragraph"
import Overlay from "./components/overlay"

import {isMobile} from "react-device-detect";

const header="OUR DESIGN INSPIRATION";
const subheader="Look around you!";

const bodyHeader_0="Design of the Zone";
const bodyPara_0="Does the design of this zone remind you of the skeleton of a body? Like the muscles and tendons that support the spine, the NIE supports the education system with our initial teacher preparation and graduate and professional development programmes. Our programmes, stretching on both sides of the spine of education, enable educators in the lifelong continuum of learning."

export default function Programmes(props){
    const {onCloseCallback,onOpenCallback,show,setShow}=props;

    var onClose=()=>{
        setShow(false);
        
    }

    useEffect(() => {
        if(show){
            onOpenCallback();
        }
        else{
            onCloseCallback();
        }
        
    }, [show]);

    if(!show){
        return (<></>)
    }

        
    return(<div className={`${isMobile?"backbone-container-mobile":"backbone-container"} animate__animated animate__fadeIn animate__faster`}>
        

        <Overlay  header={header} subheader={subheader} onClose={onClose}>
            <Row className="w-100">   
                    <BodyHeader header={bodyHeader_0}></BodyHeader>    
            </Row>
                    
            <Row className="w-100">  
                <BodyParagraph  paragraph={bodyPara_0}></BodyParagraph>
            </Row>
        </Overlay>
        
    </div>);
    
};
