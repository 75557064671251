import React,{useState} from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {white_cross} from "../../../static/images"

export default function ResearchImpact(props){
    const {studyName,author,studyType,why,what,className,back}=props;
    
    const [body, setBody] = useState(0);

    const bodies=[why,what];

    return (
        <Row className={`w-100 m-0 ${className}`}>
              
            <Col xs={{span:10,offset:1}} className="body-container p-0">
            <img onClick={()=>{back();}} src={white_cross} alt="white cross" className="back-btn"></img>

            <section className="top-section">
                <Row>
                    <Col xs={11}>
                        <h1 className="study-name">{studyName}</h1>
                    </Col>
                    
                </Row>

                <Row className="mt-2">
                    <Col >
                        <h2 className="author">By:&nbsp;{author}</h2>
                        
                    </Col>
                </Row>

                <Row>
                    <Col>   
                        <p className="study-type">
                            Type of Study:&nbsp;{studyType}
                            {/* <span className="border-bottom"></span> */}
                        </p>                             
                    </Col>
                </Row>  
            </section>
            
            <section className="mid-section">
                <Row className="">
                    <Col className={`${body===0?"active":""} d-flex justify-content-center`}>
                        <span onClick={()=>{setBody(0)}} className={`tab ${body===0?"active":""}`}>Why this study</span>
                    </Col>
                    <Col className={`${body===1?"active":""} d-flex justify-content-center`}>
                        <span onClick={()=>{setBody(1)}} className={`tab ${body===1?"active":""}`}>What is the impact</span>
                    </Col>
                </Row>
            </section>
            
            <section className="bottom-section">
            <Row className="">
                <Col>
                    <span className="body">
                        {bodies[body]}

                        {body===1?<><br/><br/>For more information on the project, please contact us at <a className="theme-link" href="mailto:oer.pubs@nie.edu.sg">oer.pubs@nie.edu.sg.</a></>:<></>}
                    </span>
                </Col>
            </Row>
            </section>

            </Col>
        </Row>
    )
}